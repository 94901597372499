import styled from 'styled-components'
import React from "react";

const TrustedWrapper = styled.section`
  padding: 4rem 2rem;
  background: linear-gradient(135deg, ${props => props.theme.background}, ${props => props.theme.background}ee);
`

const Title = styled.h2`
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: ${props => props.theme.text};
    font-family: "Caveat", sans-serif;

`

const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 2rem;
  justify-items: center;
  align-items: center;
`

const Logo = styled.img`
  max-width: 120px;
  filter: ${props => props.theme.background === '#121212' ? 'invert(1)' : 'none'};
  opacity: 0.7;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`

export default function TrustedBySection() {
    const logos = [
        'https://a.slack-edge.com/9df734f/marketing/img/homepage/true-prospects/revamp-exp/logos/logo-uber-small@2x.png',
        'https://a.slack-edge.com/a4c4a15/marketing/img/homepage/true-prospects/revamp-exp/logos/logo-target-small@2x.png',
        'https://a.slack-edge.com/a4c4a15/marketing/img/homepage/true-prospects/revamp-exp/logos/logo-etsy-small@2x.png',
        'https://a.slack-edge.com/a4c4a15/marketing/img/homepage/true-prospects/revamp-exp/logos/logo-airbnb-small@2x.png',
        'https://a.slack-edge.com/a4c4a15/marketing/img/homepage/true-prospects/revamp-exp/logos/logo-nyt-small@2x.png',
    ]

    return (
        <TrustedWrapper>
            <Title>Trusted By</Title>
            <LogoGrid>
                {logos.map((logo, index) => (
                    <Logo key={index} src={logo} alt={`Trusted company ${index + 1}`} />
                ))}
            </LogoGrid>
        </TrustedWrapper>
    )
}