import React from 'react'
import styled from 'styled-components'
import { FiArrowRight } from 'react-icons/fi'
import {useNavigate} from "react-router-dom";

const BlogWrapper = styled.section`
  padding: 4rem 2rem;
  background: ${props => props.theme.background};
`

const Title = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: ${props => props.theme.text};
`

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`

const BlogPost = styled.div`
  background: ${props => props.theme.background}88;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`

const BlogImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`

const BlogContent = styled.div`
  padding: 1.5rem;
`

const BlogTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.text};
`

const BlogExcerpt = styled.p`
  font-size: 0.9rem;
  color: ${props => props.theme.text}cc;
  margin-bottom: 1rem;
`

const ReadMoreLink = styled.a`
  color: ${props => props.theme.primary};
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.secondary};
  }
`

export default function BlogSection() {
    const blogPosts = [
        {
            title: "5 Ways AI is Revolutionizing Mental Health Care",
            excerpt: "Discover how artificial intelligence is transforming the landscape of mental health support and therapy.",
            image: "https://swyft.codesupply.co/marketing/wp-content/uploads/sites/3/2023/10/demo-image-0012-1024x768.webp",
        },
        {
            title: "The Benefits of 24/7 Emotional Support",
            excerpt: "Learn why having access to round-the-clock emotional support can significantly improve your mental well-being.",
            image: "https://swyft.codesupply.co/marketing/wp-content/uploads/sites/3/2023/10/demo-image-0026-1024x768.webp",
        },
        {
            title: "Understanding AI-Powered Therapy Sessions",
            excerpt: "Explore the inner workings of AI therapy and how it compares to traditional in-person sessions.",
            image: "https://swyft.codesupply.co/marketing/wp-content/uploads/sites/3/2023/10/demo-image-0020-1024x768.webp",
        },
        {
            title: "Personalized Mental Health: The Future of Well-being",
            excerpt: "Find out how AI is enabling highly personalized mental health care tailored to individual needs.",
            image: "https://swyft.codesupply.co/marketing/wp-content/uploads/sites/3/2023/10/Group-45-1536x1028.jpg",
        },
    ]

    const navigate = useNavigate();

    const handleGetStartedClick = () => {
        navigate("/login");
    };

    return (
        <BlogWrapper>
            <Title>Latest from BreatheIn.me</Title>
            <BlogGrid>
                {blogPosts.map((post, index) => (
                    <BlogPost key={index} onClick={handleGetStartedClick}>
                        <BlogImage src={post.image} alt={post.title} />
                        <BlogContent>
                            <BlogTitle>{post.title}</BlogTitle>
                            <BlogExcerpt>{post.excerpt}</BlogExcerpt>
                            <ReadMoreLink onClick={handleGetStartedClick}>
                                Read More <FiArrowRight />
                            </ReadMoreLink>
                        </BlogContent>
                    </BlogPost>
                ))}
            </BlogGrid>
        </BlogWrapper>
    )
}