import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ActiveCall from "./screens/ActiveCall";
import {ToastProvider} from "./components/ToastProvider";
import {CanonicalLink} from "./components/CanonicalLink";
import Landing from './screens/Landing';
import './tailwind.css';
import Login from "./screens/Login";
import DashBoard from "./screens/dashboard";
import NotFound from "./screens/NotFound";
import ChatInterface from "./screens/ChatInterface";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ToastProvider>
            <BrowserRouter>
                <CanonicalLink />
                <Routes>
                    <Route path="/" element={<Landing/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path={"/home"} element={<DashBoard/>}/>
                    <Route path="/call" element={<ChatInterface/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </BrowserRouter>
        </ToastProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
