import React from 'react';
import styled from 'styled-components';
import { FaArrowLeft, FaInfoCircle } from 'react-icons/fa';

interface HeaderProps {
    doctorImage: string | null;
    doctorName: string;
    backClick: () => void;
    infoClick: () => void;
}

interface ThemeProps {
    theme: {
        mode: 'light' | 'dark';
    };
}

const HeaderContainer = styled.header<ThemeProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: ${({ theme }) => theme.mode === 'dark' ? 'rgba(42, 8, 69, 0.9)' : 'rgba(255, 255, 255, 0.9)'};
    backdrop-filter: blur(10px);
    border-bottom: 1px solid ${({ theme }) => theme.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'};
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
`;

const BackButton = styled.button<ThemeProps>`
    background: ${({ theme }) => theme.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'};
    border: none;
    color: ${({ theme }) => theme.mode === 'dark' ? '#fff' : '#333'};
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 10px;
    border-radius: 50%;

    &:hover {
        background: ${({ theme }) => theme.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)'};
        transform: translateX(-5px);
    }
`;

const DoctorInfo = styled.div<ThemeProps>`
    display: flex;
    align-items: center;
    gap: 10px;
    background: ${({ theme }) => theme.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'};
    padding: 10px 15px;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    height: 50px;
`;

const DoctorAvatar = styled.img<ThemeProps>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.mode === 'dark' ? '#4a4e69' : '#a0a0a0'};
    object-fit: cover;
`;

const DoctorName = styled.h2<ThemeProps>`
    font-size: 18px;
    margin: 0;
    color: ${({ theme }) => theme.mode === 'dark' ? '#fff' : '#333'};
`;

const InfoButton = styled.button<ThemeProps>`
    background: ${({ theme }) => theme.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'};
    border: none;
    color: ${({ theme }) => theme.mode === 'dark' ? '#fff' : '#333'};
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 10px;
    border-radius: 50%;

    &:hover {
        background: ${({ theme }) => theme.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)'};
        transform: rotate(180deg);
    }
`;

const Header: React.FC<HeaderProps> = ({ doctorImage, backClick, infoClick, doctorName }) => (
    <HeaderContainer>
        <BackButton onClick={backClick}><FaArrowLeft /></BackButton>
        <DoctorInfo>
            <DoctorAvatar
                src={doctorImage || "https://cdn.midjourney.com/58bc3a16-055c-4876-b4f9-a9a5a13d5876/0_0.jpeg"}
                alt="Dr. Karen"
            />
            <DoctorName>{doctorName}</DoctorName>
        </DoctorInfo>
        <InfoButton onClick={infoClick}><FaInfoCircle /></InfoButton>
    </HeaderContainer>
);

export default Header;