import React from 'react'
import styled from 'styled-components'
import {WellnessCheck} from '../WellnessCheck'
import {SessionList} from '../SessionList'
import {AIInsights} from '../AIInsights'
import {SessionPrompts} from '../SessionPrompts'
import {SessionListProps} from "../../../model/Session";

const HomeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 100px;
`

const WelcomeMessage = styled.h2`
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
    color: ${props => props.theme.text};
    font-family: 'Caveat', sans-serif;
`

export const Home: React.FC<SessionListProps> = ({sessions, deleteClick, startSession, user, setPrompt}) => {
    return (
        <HomeWrapper>
            <WelcomeMessage>Welcome back {user?.displayName ?  user?.displayName : ''}</WelcomeMessage>
            <WellnessCheck/>
            <SessionList sessions={sessions} deleteClick={deleteClick} startSession={startSession} setPrompt={setPrompt} user={user}/>
            <AIInsights/>
            <SessionPrompts setPrompt={setPrompt}/>
        </HomeWrapper>
    )
}