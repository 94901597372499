/*export class Message {
    constructor(id, timestamp, isUser, text, audio) {
        this.id = id;
        this.timestamp = timestamp;
        this.isUser = isUser;
        this.text = text;
        this.audio = audio;
    }
}*/

export type Message = {
    id: string,
    timestamp: number,
    isUser: boolean,
    text: string,
    audio: string,
}

export function sortMessagesByTimestamp(messages: Message[]) {
    return messages.sort((a, b) => a.timestamp - b.timestamp);
}

export function isLastMessageFromUser(messages: Message[]) {
    return messages.length > 0 && messages[messages.length - 1].isUser;
}

export function isLastMessageTooOld(messages: Message[], minutes: number) {
    return messages.length > 0 && Date.now() - messages[messages.length - 1].timestamp > minutes * 60 * 1000;
}