import React from 'react'
import styled from 'styled-components'
import {FiArrowRight} from 'react-icons/fi'
import {useNavigate} from "react-router-dom";

const HeroWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 80px);
    padding: 2rem;
    text-align: center;
    background: linear-gradient(135deg, ${props => props.theme.primary}22, ${props => props.theme.secondary}22);
`

const GlassCard = styled.div`
    background: ${props => props.theme.background}22;
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 3rem;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
`

const Headline = styled.h1`
    font-size: 3.5rem;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, ${props => props.theme.primary}, ${props => props.theme.secondary});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "DinBold", sans-serif;

    @media (max-width: 768px) {
        font-size: 2.5rem;
    }
`

const Subheadline = styled.p`
    font-size: 1.25rem;
    margin-bottom: 2rem;
    color: ${props => props.theme.text};
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`

const CTAButton = styled.button`
    background: linear-gradient(135deg, ${props => props.theme.primary}, ${props => props.theme.secondary});
    color: #ffffff;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-3px);
    }

    @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
    }
`

const SecondaryButton = styled(CTAButton)`
    background: transparent;
    border: 2px solid ${props => props.theme.primary};
    color: ${props => props.theme.primary};
`

export default function HeroSection() {
    const navigate = useNavigate();

    const handleGetStartedClick = () => {
        navigate("/login");
    };

    const handleExploreFeaturesClick = () => {
        //console.log("Explore Features clicked");
        const featuresSection = document.querySelector("#features");
        if (featuresSection) {
            featuresSection.scrollIntoView({behavior: "smooth"});
        }
    };
    return (
        <HeroWrapper>
            <GlassCard>
                <Headline>Your AI Therapist, Always Here for You</Headline>
                <Subheadline>
                    Experience 24/7 emotional support and personal growth with BreathIn. Our advanced AI understands you
                    like a human therapist, but is available anytime, anywhere.
                </Subheadline>
                <ButtonContainer>
                    <CTAButton onClick={handleGetStartedClick}>
                        Get Started For Free <FiArrowRight/>
                    </CTAButton>
                    <SecondaryButton onClick={handleExploreFeaturesClick}>
                        Explore Features
                    </SecondaryButton>
                </ButtonContainer>
            </GlassCard>
        </HeroWrapper>
    )
}