import firebase from 'firebase/compat/app';
import {get, getDatabase, off, onValue, ref} from "firebase/database";
import {getAnalytics} from "firebase/analytics";
import {onAuthStateChanged, getAuth} from "firebase/auth";
import {getMessaging, getToken} from "firebase/messaging";
import {sortMessagesByTimestamp} from "../model/Message";
import {Message} from "../model/Message";
import {NavigateFunction} from "react-router-dom";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD8b_bdks10zcyx0DcTbGaWNjhweHWRaRg",
    authDomain: "breathein-app.firebaseapp.com",
    databaseURL: "https://breathein-app-default-rtdb.firebaseio.com",
    projectId: "breathein-app",
    storageBucket: "breathein-app.appspot.com",
    messagingSenderId: "920764875708",
    appId: "1:920764875708:web:518985aa710a61cc7261b9",
    measurementId: "G-QWJVYXCZ7J"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const messaging = getMessaging();

export const analytics = getAnalytics();

export function initializeFirebase() {
    return firebaseApp;
}

export const database = getDatabase();

export const currentUser = getAuth()?.currentUser;

export function signOutListener(navigate: NavigateFunction) {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (!currentUser) {
            // User is signed out
            navigate('/');
        }
    });
    return () => unsubscribe();
}

export function hepticFeedback(duration = 10) {
    navigator.vibrate(duration);
}

export function getFirebaseTimestamp() {
    return firebase.database.ServerValue.TIMESTAMP;
}

export function getSessionMessages(sessionId: string, setMessages: (messages: Message[]) => void) {
    const messagesRef: any = ref(getDatabase(), `messages/${sessionId}`);

    onValue(messagesRef, (snapshot) => {
        const messages: any = snapshot.val();
        let messagesArray: Message[] = [];
        if (messages) {
            Object.keys(messages).forEach((messageId) => {
                const messageData: Message = messages[messageId];
                //console.log('Message5:', messageData == null);
                if (messageData !== undefined && messageData !== null) {
                    messagesArray.push(messageData);
                }
            });
        } else {
            console.log('No messages found for this session.');
        }
        // reverse the array to show the latest messages at the bottom
        setMessages(sortMessagesByTimestamp(messagesArray));
    });
    //return off(messagesRef);
}