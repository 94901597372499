import styled from 'styled-components'
import { FiPlus, FiMinus } from 'react-icons/fi'
import React, {useState} from "react";

const FAQsWrapper = styled.section`
  padding: 4rem 2rem;
  background: linear-gradient(135deg, ${props => props.theme.primary}11, ${props => props.theme.secondary}11);
`

const Title = styled.h2`
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: ${props => props.theme.text};
    font-family: "Caveat", sans-serif;
`

const FAQList = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

const FAQItem = styled.div`
  background: ${props => props.theme.background}66;
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
`

const FAQQuestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: ${props => props.theme.text};
  font-weight: bold;
`

const FAQAnswer = styled.div<{ isOpen: boolean }>`
  margin-top: ${props => (props.isOpen ? '1rem' : '0')};
  max-height: ${props => (props.isOpen ? '1000px' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  color: ${props => props.theme.text}cc;
`

const ToggleIcon = styled.div`
  color: ${props => props.theme.primary};
`

export default function FAQsSection() {
    const [openIndex, setOpenIndex] = useState<number | null>(null)

    const faqs = [
        {
            question: 'How does AI therapy work?',
            answer: 'AI therapy uses advanced natural language processing to understand your concerns and provide personalized support and guidance.',
        },
        {
            question: 'Is my data secure?',
            answer: 'Yes, we take data security very seriously. All conversations are encrypted, and we adhere to strict privacy policies.',
        },
        {
            question: 'Can AI therapy replace traditional therapy?',
            answer: "While AI therapy can be a valuable tool for mental health support, it's not intended to replace traditional therapy with a licensed professional.",
        },
        {
            question: 'How often should I use the app?',
            answer: 'We recommend daily check-ins for the best results, but you can use the app as often as you feel necessary.',
        },
    ]

    const toggleFAQ = (index: number) => {
        setOpenIndex(openIndex === index ? null : index)
    }

    return (
        <FAQsWrapper id={`faq`}>
            <Title>Frequently Asked Questions</Title>
            <FAQList>
                {faqs.map((faq, index) => (
                    <FAQItem key={index}>
                        <FAQQuestion onClick={() => toggleFAQ(index)}>
                            {faq.question}
                            <ToggleIcon>
                                {openIndex === index ? <FiMinus /> : <FiPlus />}
                            </ToggleIcon>
                        </FAQQuestion>
                        <FAQAnswer isOpen={openIndex === index}>{faq.answer}</FAQAnswer>
                    </FAQItem>
                ))}
            </FAQList>
        </FAQsWrapper>
    )
}