import React from 'react'
import styled from 'styled-components'
import { FiCheck, FiStar } from 'react-icons/fi'
import {useNavigate} from "react-router-dom";

const PricingWrapper = styled.section`
    padding: 4rem 2rem;
    background: linear-gradient(135deg, ${props => props.theme.secondary}11, ${props => props.theme.primary}11);
`

const Title = styled.h2`
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: ${props => props.theme.text};
    font-family: "Caveat", sans-serif;
`

const PricingGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
`

const PricingCard = styled.div<{ popular?: boolean }>`
    background: ${props => props.theme.background}66;
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    position: relative;
    border: ${props => props.popular ? `2px solid ${props.theme.primary}` : 'none'};

    &:hover {
        transform: translateY(-5px);
    }
`

const PopularBanner = styled.div`
    position: absolute;
    top: 0;
    right: 2rem;
    background: ${props => props.theme.primary};
    color: #ffffff;
    padding: 0.5rem 1rem;
    border-radius: 0 0 10px 10px;
    font-size: 0.9rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.5rem;
`

const PlanTitle = styled.h3`
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: ${props => props.theme.primary};
`

const PlanPrice = styled.p`
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: ${props => props.theme.text};
`

const PlanFeatures = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 2rem;
`

const PlanFeature = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    color: ${props => props.theme.text}cc;
`

const FeatureIcon = styled(FiCheck)`
    color: ${props => props.theme.secondary};
    margin-right: 0.5rem;
`

const CTAButton = styled.button`
  background: linear-gradient(135deg, ${props => props.theme.primary}, ${props => props.theme.secondary});
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`

export default function PricingSection() {
    const plans = [
        {
            title: 'Basic',
            price: '$9.99/month',
            features: ['Daily check-ins', 'Mood tracking', 'Guided meditations'],
        },
        {
            title: 'Pro',
            price: '$19.99/month',
            features: ['Everything in Basic', 'Unlimited AI therapy sessions', 'Personalized insights'],
            popular: true,
        },
        {
            title: 'Enterprise',
            price: 'Custom',
            features: ['Everything in Pro', 'Dedicated support', 'Custom integrations'],
        },
    ]

    const navigate = useNavigate();

    const handleGetStartedClick = () => {
        navigate("/login");
    };

    return (
        <PricingWrapper id={`pricing`}>
            <Title>Choose Your Plan</Title>
            <PricingGrid>
                {plans.map((plan, index) => (
                    <PricingCard key={index} popular={plan.popular}>
                        {plan.popular && (
                            <PopularBanner>
                                <FiStar /> Popular
                            </PopularBanner>
                        )}
                        <PlanTitle>{plan.title}</PlanTitle>
                        <PlanPrice>{plan.price}</PlanPrice>
                        <PlanFeatures>
                            {plan.features.map((feature, featureIndex) => (
                                <PlanFeature key={featureIndex}>
                                    <FeatureIcon /> {feature}
                                </PlanFeature>
                            ))}
                        </PlanFeatures>
                        <CTAButton onClick={handleGetStartedClick}>Get Started</CTAButton>
                    </PricingCard>
                ))}
            </PricingGrid>
        </PricingWrapper>
    )
}