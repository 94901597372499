import React from 'react';
import styled from 'styled-components';

interface MessageBubbleProps {
    children: React.ReactNode;
    isUser?: boolean;
}

export interface ThemeProps {
    theme: {
        mode: 'light' | 'dark';
    };
}

const Bubble = styled.div<MessageBubbleProps & ThemeProps>`
    max-width: 70%;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    word-wrap: break-word;

    ${({ isUser, theme }) => isUser ? `
    background: ${theme.mode === 'dark' ? 'rgba(0, 180, 216, 0.7)' : 'rgba(0, 119, 182, 0.7)'};
    align-self: flex-end;
    border-bottom-right-radius: 0;
    margin-left: auto;
  ` : `
    background: ${theme.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'};
    align-self: flex-start;
    border-bottom-left-radius: 0;
    margin-right: auto;
  `}
`;

const MessageBubble: React.FC<MessageBubbleProps> = ({ children, isUser }) => (
    <Bubble isUser={isUser}>
        {children}
    </Bubble>
);

export default MessageBubble;