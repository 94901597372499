import React, {CSSProperties} from 'react';
import styled, {keyframes} from 'styled-components';
import {FaMicrophone, FaMoon, FaStop, FaSun} from 'react-icons/fa';
import {Button} from "../dashboard/SharedComponents";
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner';
import {isLastMessageFromUser, Message} from "../../model/Message";


interface InputAreaProps {
    isRecording: boolean;
    toggleRecording: (save: boolean) => void;
    cancelRecording: () => void;
    toggleTheme: () => void;
    recordingTime: string;
    theme: 'dark' | 'light';
    messages: Message[];
}

interface ThemeProps {
    mode: 'dark' | 'light';
}

const pulseAnimation = keyframes`
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    }
    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
`;

const InputAreaContainer = styled.div<ThemeProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: ${props => props.mode === 'dark' ? 'rgba(42, 8, 69,0.8)' : 'rgba(255, 255, 255, 0.8)'};
    backdrop-filter: blur(7px);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`;

const RecordButton = styled(Button)<{ isRecording: boolean, fromUser?: boolean }>`
    background: rgba(255, 0, 0, 0.8);    color: #fff;
    font-size: 24px;
    width: 30px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${props => !props.fromUser? 'pointer' : 'not-allowed'};
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
    animation: ${props => !props.fromUser ? pulseAnimation : 'none'} 2s infinite;
    pointer-events: ${props => !props.fromUser ? 'auto' : 'none'};

    &:hover {
        transform: scale(1.1);
    }
`;

const Timer = styled.div<ThemeProps>`
    font-size: 24px;
    font-weight: bold;
    margin: 0 20px;
    color: ${props => props.mode === 'dark' ? '#fff' : '#333'};
`;

const CancelButton = styled(Button)<ThemeProps>`
    background: ${props => props.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'};
    color: ${props => props.mode === 'dark' ? '#fff' : '#333'};
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 20px;
    margin-right: 40px;

    &:hover {
        background: ${props => props.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)'};
    }
`;

const ThemeToggle = styled(Button)<ThemeProps>`
    background: ${props => props.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'};
    color: ${props => props.mode === 'dark' ? '#fff' : '#333'};
    font-size: 24px;
    padding: 10px;
    border-radius: 50%;
    margin-left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: ${props => props.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)'};
    }
`;

const InputArea: React.FC<InputAreaProps> = ({
                                                 isRecording,
                                                 toggleRecording,
                                                 cancelRecording,
                                                 toggleTheme,
                                                 recordingTime,
                                                 messages,
                                                 theme
                                             }) => (
    <InputAreaContainer mode={theme}>
        {isRecording ? (
            <>
                <CancelButton onClick={cancelRecording} mode={theme}>Cancel</CancelButton>
                <RecordButton onClick={()=> toggleRecording(true)} isRecording={isRecording}>
                    <FaStop/>
                </RecordButton>
                <Timer mode={theme}>{recordingTime}</Timer>
            </>
        ) : (
            <>
                <RecordButton onClick={()=> toggleRecording(true)} isRecording={isRecording}
                              fromUser={isLastMessageFromUser(messages)}>
                    <FaMicrophone/>
                    <SlSpinner className={`record-button-spinner ${!isLastMessageFromUser(messages) ? 'hidden' : ''}`}
                               style={
                                   {
                                       fontSize: '3rem',
                                       '--spinner-size': '90px',
                                       '--track-width': '6px',
                                       '--indicator-color': '#194153',
                                       marginTop: '15px',
                                       marginBottom: '20px',
                                   } as CSSProperties
                               }
                    />
                </RecordButton>
                <ThemeToggle onClick={toggleTheme} mode={theme}>
                    {theme === 'dark' ? <FaSun/> : <FaMoon/>}
                </ThemeToggle>
            </>
        )}
    </InputAreaContainer>
);

export default InputArea;