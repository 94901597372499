import React, { useState } from 'react'
import styled from 'styled-components'
import { FiBell, FiLock, FiUser, FiToggleLeft, FiToggleRight } from 'react-icons/fi'
import { GlassCard, CardTitle, Button } from '../SharedComponents'

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 100px;
`

const SettingCard = styled(GlassCard)`
  width: 100%;
`

const SettingItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid ${props => props.theme.primary}33;

  &:last-child {
    border-bottom: none;
  }
`

const SettingLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const Toggle = styled.button<{ isOn: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.isOn ? props.theme.primary : props.theme.textSecondary};
  font-size: 1.5rem;
`

const Input = styled.input`
  background: ${props => props.theme.surface};
  border: 1px solid ${props => props.theme.primary}33;
  border-radius: 4px;
  padding: 0.5rem;
  color: ${props => props.theme.text};
  width: 200px;
`

export const Settings: React.FC = () => {
  const [notifications, setNotifications] = useState(true)
  const [darkMode, setDarkMode] = useState(true)
  const [twoFactor, setTwoFactor] = useState(false)
  const [email, setEmail] = useState('user@example.com')
  const [password, setPassword] = useState('********')

  return (
    <SettingsWrapper>
      <CardTitle>Settings</CardTitle>
      
      <SettingCard>
        <CardTitle>Notifications</CardTitle>
        <SettingItem>
          <SettingLabel>
            <FiBell />
            Push Notifications
          </SettingLabel>
          <Toggle isOn={notifications} onClick={() => setNotifications(!notifications)}>
            {notifications ? <FiToggleRight /> : <FiToggleLeft />}
          </Toggle>
        </SettingItem>
      </SettingCard>

      <SettingCard>
        <CardTitle>Appearance</CardTitle>
        <SettingItem>
          <SettingLabel>
            Dark Mode
          </SettingLabel>
          <Toggle isOn={darkMode} onClick={() => setDarkMode(!darkMode)}>
            {darkMode ? <FiToggleRight /> : <FiToggleLeft />}
          </Toggle>
        </SettingItem>
      </SettingCard>

      <SettingCard>
        <CardTitle>Security</CardTitle>
        <SettingItem>
          <SettingLabel>
            <FiLock />
            Two-Factor Authentication
          </SettingLabel>
          <Toggle isOn={twoFactor} onClick={() => setTwoFactor(!twoFactor)}>
            {twoFactor ? <FiToggleRight /> : <FiToggleLeft />}
          </Toggle>
        </SettingItem>
      </SettingCard>

      <SettingCard>
        <CardTitle>Account</CardTitle>
        <SettingItem>
          <SettingLabel>
            <FiUser />
            Email
          </SettingLabel>
          <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </SettingItem>
        <SettingItem>
          <SettingLabel>
            <FiLock />
            Password
          </SettingLabel>
          <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </SettingItem>
      </SettingCard>

      <Button style={{marginTop: '2rem'}}>Save Changes</Button>
    </SettingsWrapper>
  )
}