import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {InfoIcon, Pause, Play} from 'lucide-react'
import {ThemeProps} from "./MessageBubble";

interface AudioBubbleProps {
    audioUrl: string
    onError?: (error: Error) => void
    isUser?: boolean
    message?: string
}

const BubbleContainer = styled.div<{ user?: boolean }>`
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 30px;
    padding: 10px 15px;
    margin: 0 0 15px 0;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    align-self: ${props => props.user ? 'flex-end' : 'flex-start'};
    margin-left: ${props => props.user ? 'auto' : 'initial'};
    margin-right: ${props => props.user ? 'initial' : 'auto'};
    background: ${props => props.user ? 'rgba(0, 180, 216, 0.7)' : 'rgba(255, 255, 255, 0.2)'};
    border-bottom-right-radius: ${props => props.user ? '0' : '30px'};
    border-bottom-left-radius: ${props => props.user ? '30px' : '0'};
`;

const ControlsContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`

const PlayButton = styled.button<{ theme: ThemeProps }>`
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 5px;
    color: ${props => props.theme.mode === 'dark' ? '#FFF' : '#333'};
    display: flex;
    align-items: center;
    justify-content: center;
`

const WaveformContainer = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    flex-grow: 1;
    cursor: pointer;
    width: max-content;
`

export const BubbleText = styled.p<{theme: ThemeProps, visible: boolean}>`
    margin: 5px;
    color: ${props => props.theme.mode === 'dark' ? '#FFF' : '#333'};
    display: ${props => props.visible ? 'block' : 'none'};
`

const WaveformBar = styled.div<{ height: number; active: boolean, theme: ThemeProps }>`
    width: 2px;
    height: ${props => props.height}%;
    background-color: ${props => props.active ? props.theme.mode === 'dark' ? '#FFF' : '#333' : 'rgba(0, 0, 0, 0.2)'};
    margin-right: 2px;
    transition: background-color 0.2s ease;
`

const TimeDisplay = styled.div<{ theme: ThemeProps }>`
    font-size: 12px;
    color: ${props => props.theme.mode === 'dark' ? '#FFF' : '#333'};
    margin-left: 10px;
`

const AudioBubble: React.FC<AudioBubbleProps & ThemeProps> = ({audioUrl, onError, theme, isUser, message}) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const [waveform, setWaveform] = useState<number[]>([])
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const audioRef = useRef<HTMLAudioElement | null>(null)
    const containerRef = useRef<HTMLDivElement | null>(null)
    const [textVisible, setTextVisible] = useState(false)

    useEffect(() => {
        const audio = new Audio(audioUrl)
        audioRef.current = audio

        audio.addEventListener('loadedmetadata', () => setDuration(audio.duration))
        audio.addEventListener('timeupdate', updateTime)
        audio.addEventListener('ended', () => setIsPlaying(false))
        audio.addEventListener('error', handleError)

        // Generate a random waveform for demonstration
        const divisor = Math.floor(Math.random() * 5) + 8
        const length = Math.floor(window.innerWidth / 10)
        setWaveform(Array.from({length: length}, () => Math.floor(Math.random() * 100)))

        return () => {
            audio.removeEventListener('loadedmetadata', () => setDuration(audio.duration))
            audio.removeEventListener('timeupdate', updateTime)
            audio.removeEventListener('ended', () => setIsPlaying(false))
            audio.removeEventListener('error', handleError)
            audio.pause()
        }
    }, [audioUrl])

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (!entries[0].isIntersecting && isPlaying) {
                    handleTogglePlay()
                }
            },
            {threshold: 0.5}
        )

        if (containerRef.current) {
            observer.observe(containerRef.current)
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current)
            }
        }
    }, [isPlaying])

    const updateTime = () => {
        if (audioRef.current) {
            setCurrentTime(audioRef.current.currentTime)
        }
    }

    const handleTextVisibility = () => {
        setTextVisible(!textVisible)
    }

    const handleError = (e: Event) => {
        if (onError && e instanceof ErrorEvent) {
            onError(new Error(e.message))
        }
    }

    const handleTogglePlay = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause()
            } else {
                audioRef.current.play()
            }
            setIsPlaying(!isPlaying)
        }
    }

    const handleScrub = (e: React.MouseEvent<HTMLDivElement>) => {
        if (audioRef.current) {
            const waveformRect = e.currentTarget.getBoundingClientRect()
            const clickPosition = (e.clientX - waveformRect.left) / waveformRect.width
            audioRef.current.currentTime = clickPosition * duration
            setCurrentTime(audioRef.current.currentTime)
        }
    }

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60)
        const seconds = Math.floor(time % 60)
        return `${minutes}:${seconds.toString().padStart(2, '0')}`
    }

    return (
        <BubbleContainer ref={containerRef} user={isUser}>
            <ControlsContainer>
                <PlayButton onClick={handleTogglePlay}>
                    {isPlaying ? <Pause size={24}/> : <Play size={24}/>}
                </PlayButton>
                <WaveformContainer onClick={handleScrub}>
                    {waveform.map((height, index) => (
                        <WaveformBar
                            key={index}
                            height={height}
                            active={index / waveform.length <= currentTime / duration}
                        />
                    ))}
                </WaveformContainer>
                {/*<TimeDisplay>{formatTime(currentTime)} / {formatTime(duration)}</TimeDisplay>*/}
                <InfoIcon size={24} onClick={handleTextVisibility}/>
            </ControlsContainer>
            <BubbleText visible={textVisible}>{message}</BubbleText>
        </BubbleContainer>
    )
}

export default AudioBubble