import React from 'react'
import styled from 'styled-components'
import { FaRobot, FaArrowRight } from 'react-icons/fa'
import logo from "../assets/images/logo-512.png";

const PageWrapper = styled.div`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
    color: #e0e0e0;
    font-family: 'DinRegular', sans-serif;
    padding: 2rem;
    font-size: 1.3rem;
`

const GlassContainer = styled.div`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 3rem;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 2rem;
  }
`

const Title = styled.h1`
    font-size: 4.5rem;
    margin-bottom: 1rem;
    margin-top: -40px;
    background: linear-gradient(90deg, #00d2ff 0%, #3a7bd5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'DinBold', sans-serif;

    @media (max-width: 768px) {
        font-size: 3rem;
    }
`

const Subtitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 2rem;
  color: #b3e5fc;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`

const Description = styled.p`
    font-size: 1.3rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    margin-top: -20px;
    max-width: 480px;
`

const CTAButton = styled('a')`
  display: inline-flex;
  align-items: center;
  background: linear-gradient(90deg, #00d2ff 0%, #3a7bd5 100%);
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }

  svg {
    margin-left: 0.5rem;
  }
`

const IconWrapper = styled.div`
  font-size: 6rem;
  margin-bottom: 2rem;
  color: #00d2ff;
`

export default function NotFound() {
    return (
        <PageWrapper>
            <GlassContainer>
                <IconWrapper>
                    <img src={logo} width={200} height={200}/>
                </IconWrapper>
                <Title>404</Title>
                <Subtitle>Oops! Page not found</Subtitle>
                <Description>
                    It seems you've wandered into uncharted territory. But don't worry, BreatheIn is here to guide you back to a
                    place of calm and clarity.
                </Description>
                <CTAButton href="/login">
                    Start Your Journey <FaArrowRight />
                </CTAButton>
            </GlassContainer>
        </PageWrapper>
    )
}