import { createGlobalStyle, DefaultTheme } from 'styled-components'

// Define a type for your theme
interface MyTheme extends DefaultTheme {
  mode: 'dark' | 'light';
  primary: string;
  secondary: string;
  background: string;
  surface: string;
  text: string;
  textSecondary: string;
}

// Modify your themes using this type
export const darkTheme: MyTheme = {
  mode: 'dark',
  primary: '#3a86ff',
  secondary: '#8338ec',
  background: '#1a1a2e',
  surface: 'rgba(255, 255, 255, 0.05)',
  text: '#ffffff',
  textSecondary: '#a0a0a0',
}

export const lightTheme: MyTheme = {
  mode: 'light',
  primary: '#3a86ff',
  secondary: '#8338ec',
  background: '#f0f2f5',
  surface: 'rgba(255, 255, 255, 0.7)',
  text: '#333333',
  textSecondary: '#666666',
}

// Use the theme in your global styles
export const GlobalStyle = createGlobalStyle<{ theme: MyTheme }>`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    background: ${props => props.theme.mode === 'dark' ? '#1a1a2e' : '#f0f2f5'};
    color: ${props => props.theme.mode === 'dark' ? '#ffffff' : '#333333'};
    transition: all 0.3s ease;
  }
`
