import React from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import '../styles/components/ConfirmDialog.css';

export function ConfirmDialog({open, setOpen, alertData}) {

    function performAlertAction(action, onClose) {
        if (typeof action === 'function')
            action();
        onClose();
    }

    return <AlertDialog.Root open={open}>
        <AlertDialog.Portal>
            <AlertDialog.Overlay className="AlertDialogOverlay"/>
            <AlertDialog.Content className="AlertDialogContent">
                <AlertDialog.Title className="AlertDialogTitle">{alertData?.title}</AlertDialog.Title>
                <AlertDialog.Description className="AlertDialogDescription">
                    {alertData?.message}
                </AlertDialog.Description>
                <div style={{display: 'flex', gap: 25, justifyContent: 'flex-end'}}>
                    <AlertDialog.Cancel asChild>
                        <button className="Button mauve" onClick={alertData?.negativeClick}>{alertData?.negativeText}</button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Action asChild>
                        <button className="Button red" onClick={alertData?.positiveClick}>{alertData?.positiveText}</button>
                    </AlertDialog.Action>
                </div>
            </AlertDialog.Content>
        </AlertDialog.Portal>
    </AlertDialog.Root>
}
