import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function CanonicalLink() {
    const location = useLocation();

    useEffect(() => {
        const canonicalLink = document.querySelector('link[rel="canonical"]');
        if (canonicalLink) {
            canonicalLink.href = `${window.location.origin}${location.pathname}`;
        } else {
            const link = document.createElement('link');
            link.rel = 'canonical';
            link.href = `${window.location.origin}${location.pathname}`;
            document.head.appendChild(link);
        }
    }, [location]);

    return null;
}
