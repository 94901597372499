import styled from 'styled-components'
import { FiTwitter, FiFacebook, FiInstagram, FiLinkedin } from 'react-icons/fi'
import React from "react";

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  padding: 4rem 2rem;
`

const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
`

const FooterSection = styled.div`
  margin-bottom: 2rem;
`

const FooterTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.primary};
`

const FooterLink = styled.a`
  display: block;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.text}cc;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.primary};
  }
`

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`

const SocialIcon = styled.a`
  color: ${props => props.theme.text};
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.primary};
  }
`

const Copyright = styled.p`
  text-align: center;
  margin-top: 2rem;
  color: ${props => props.theme.text}99;
`

export default function Footer() {
    return (
        <FooterWrapper>
            <FooterContent>
                <FooterSection>
                    <FooterTitle>BreatheIn.me</FooterTitle>
                    <FooterLink href="#">About Us</FooterLink>
                    <FooterLink href="#">Careers</FooterLink>
                    <FooterLink href="#">Press</FooterLink>
                </FooterSection>
                <FooterSection>
                    <FooterTitle>Resources</FooterTitle>
                    <FooterLink href="#">Blog</FooterLink>
                    <FooterLink href="#">Help Center</FooterLink>
                    <FooterLink href="#">Contact Us</FooterLink>
                </FooterSection>
                <FooterSection>
                    <FooterTitle>Legal</FooterTitle>
                    <FooterLink href="#">Terms of Service</FooterLink>
                    <FooterLink href="#">Privacy Policy</FooterLink>
                    <FooterLink href="#">Cookie Policy</FooterLink>
                </FooterSection>
                <FooterSection>
                    <FooterTitle>Connect With Us</FooterTitle>
                    <SocialIcons>
                        <SocialIcon href="#"><FiTwitter /></SocialIcon>
                        <SocialIcon href="#"><FiFacebook /></SocialIcon>
                        <SocialIcon href="#"><FiInstagram /></SocialIcon>
                        <SocialIcon href="#"><FiLinkedin /></SocialIcon>
                    </SocialIcons>
                </FooterSection>
            </FooterContent>
            <Copyright>&copy; {new Date().getFullYear()} BreatheIn.me. All rights reserved.</Copyright>
        </FooterWrapper>
    )
}