import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FiHome, FiDollarSign, FiHelpCircle, FiLogIn, FiMenu, FiX, FiPlay } from 'react-icons/fi'
import {useNavigate} from "react-router-dom";

const HeaderWrapper = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: ${props => props.theme.background}cc;
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 1rem;
    }
`

const Logo = styled.h1`
    font-size: 1.8rem;
    font-weight: bold;
    color: ${props => props.theme.text};
    font-family: 'Caveat', sans-serif;
`

const Nav = styled.nav`
    display: flex;
    gap: 1.5rem;

    @media (max-width: 768px) {
        display: none;
    }
`

const NavLink = styled.a`
    color: ${props => props.theme.text};
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: color 0.3s ease;

    &:hover {
        color: ${props => props.theme.primary};
    }
`

const MobileMenuIcon = styled.button`
    display: none;
    background: none;
    border: none;
    color: ${props => props.theme.text};
    font-size: 1.5rem;
    cursor: pointer;

    @media (max-width: 768px) {
        display: block;
    }
`

const MobileMenu = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 300px;
    background-color: ${props => props.theme.background};
    transform: translateX(${props => (props.isOpen ? '0' : '100%')});
    transition: transform 0.3s ease-in-out;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    z-index: 1001;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
`

const MobileNavLink = styled(NavLink)`
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${props => props.theme.primary}22;
    }
`

const CloseButton = styled.button`
    align-self: flex-end;
    background: none;
    border: none;
    color: ${props => props.theme.text};
    font-size: 1.5rem;
    cursor: pointer;
    margin-bottom: 1rem;
`

const MobileMenuHeader = styled.div`
    text-align: center;
    margin-bottom: 2rem;
`

const MobileMenuTitle = styled.h2`
    font-size: 1.5rem;
    color: ${props => props.theme.primary};
    margin-bottom: 0.5rem;
`

const MobileMenuSubtitle = styled.p`
    font-size: 0.9rem;
    color: ${props => props.theme.text}aa;
`

const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.isOpen ? 'block' : 'none')};
  z-index: 1000;
`

export default function Header() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen)
    }

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false)
    }

    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'visible'
        }

        return () => {
            document.body.style.overflow = 'visible'
        }
    }, [isMobileMenuOpen])

    const navigate = useNavigate();

    const handleGetStartedClick = () => {
        navigate("/login");
    };

    const navItems = [
        //{ href: "#session", icon: <FiPlay />, text: "Start a Session" },
        { href: "#features", icon: <FiHome />, text: "Features" },
        { href: "#pricing", icon: <FiDollarSign />, text: "Pricing" },
        { href: "#faq", icon: <FiHelpCircle />, text: "FAQ" },
        { href: "#login", icon: <FiLogIn />, text: "Login" , onclick: handleGetStartedClick},
    ]

    return (
        <>
            <HeaderWrapper>
                <Logo>BreatheIn</Logo>
                <Nav>
                    {navItems.map((item, index) => (
                        <NavLink key={index} href={item.href} onClick={item.onclick}>
                            {item.icon} {item.text}
                        </NavLink>
                    ))}
                </Nav>
                <MobileMenuIcon onClick={toggleMobileMenu} aria-label="Open menu">
                    <FiMenu />
                </MobileMenuIcon>
            </HeaderWrapper>
            <Overlay isOpen={isMobileMenuOpen} onClick={closeMobileMenu} />
            <MobileMenu isOpen={isMobileMenuOpen}>
                <CloseButton onClick={closeMobileMenu} aria-label="Close menu">
                    <FiX />
                </CloseButton>
                <MobileMenuHeader>
                    <MobileMenuTitle>BreatheIn</MobileMenuTitle>
                    <MobileMenuSubtitle>Your AI therapist, always here for you</MobileMenuSubtitle>
                </MobileMenuHeader>
                {navItems.map((item, index) => (
                    <MobileNavLink key={index} href={item.href} onClick={closeMobileMenu}>
                        {item.icon} {item.text}
                    </MobileNavLink>
                ))}
            </MobileMenu>
        </>
    )
}