import styled from 'styled-components'
import { FiUserPlus, FiMessageSquare, FiTrendingUp, FiSmile } from 'react-icons/fi'
import React from "react";

const StepsWrapper = styled.section`
  padding: 4rem 2rem;
  background: linear-gradient(135deg, ${props => props.theme.primary}11, ${props => props.theme.secondary}11);
`

const Title = styled.h2`
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: ${props => props.theme.text};
    font-family: "Caveat", sans-serif;
`

const StepsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`

const StepCard = styled.div`
  background: ${props => props.theme.background}66;
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`

const StepIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.primary};
`

const StepTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.text};
`

const StepDescription = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.text}cc;
`

export default function StepsSection() {
    const steps = [
        { icon: <FiUserPlus />, title: 'Sign Up', description: 'Create your account and set up your profile' },
        { icon: <FiMessageSquare />, title: 'Start a Session', description: 'Connect with our AI therapist anytime, anywhere' },
        { icon: <FiTrendingUp />, title: 'Track Progress', description: 'Monitor your mental health journey with insights and analytics' },
        { icon: <FiSmile />, title: 'Feel Better', description: 'Experience improved well-being and mental clarity' },
    ]

    return (
        <StepsWrapper>
            <Title>How It Works</Title>
            <StepsGrid>
                {steps.map((step, index) => (
                    <StepCard key={index}>
                        <StepIcon>{step.icon}</StepIcon>
                        <StepTitle>{step.title}</StepTitle>
                        <StepDescription>{step.description}</StepDescription>
                    </StepCard>
                ))}
            </StepsGrid>
        </StepsWrapper>
    )
}
