import React, {useEffect, useState} from 'react'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import {FaArrowLeft, FaGoogle, FaMoon, FaSun} from 'react-icons/fa'
import logo from "../assets/images/logo-512.png";
import {useNavigate} from "react-router-dom";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {GoogleAuthProvider, signInWithPopup, signOut} from "@firebase/auth";
import {initializeFirebase} from "../utils/FirebaseUtils";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    transition: all 0.3s ease;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
  }
`

const darkTheme = {
    background: 'linear-gradient(135deg, #2c3e50, #000000)',
    cardBackground: 'rgba(255, 255, 255, 0.1)',
    text: '#ffffff',
    buttonGradient: 'linear-gradient(135deg, #3498db, #8e44ad)',
    buttonHoverGradient: 'linear-gradient(135deg, #2980b9, #9b59b6)',
}

const lightTheme = {
    background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
    cardBackground: 'rgba(255, 255, 255, 0.7)',
    text: '#333333',
    buttonGradient: 'linear-gradient(135deg, #3498db, #9b59b6)',
    buttonHoverGradient: 'linear-gradient(135deg, #2980b9, #8e44ad)',
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: ${({ theme }) => theme.background};
`

const LoginCard = styled.div`
    background: ${({theme}) => theme.cardBackground};
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: 100%;
    max-width: 400px;
    text-align: center;
    transition: all 0.3s ease;

    @media (max-width: 480px) {
        padding: 20px;
        width: 90%;
    }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.text};
  margin-bottom: 20px;
  font-size: 3rem;
    font-family: "Caveat", sans-serif;
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.text};
  margin-bottom: 30px;
  font-size: 1.2rem;
  font-weight: 300;
`

const Logo = styled.img`
    width: 180px;
    height: 180px;
    margin-bottom: 20px;
    align-self: center;
`

const Button = styled.button`
    background: ${({theme}) => theme.buttonGradient};
    color: white;
    border: none;
    padding: 12px 20px;
    margin: 0 20px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 70%;
    transition: all 0.3s ease;

    &:hover {
        background: ${({theme}) => theme.buttonHoverGradient};
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    }
`

const ThemeToggle = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`
const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`

const provider = new GoogleAuthProvider();
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
initializeFirebase();

const Logout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });
}

export default function Login() {
    const [isDarkMode, setIsDarkMode] = useState(true)
    const navigate = useNavigate()

    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode)
    }

    const goBack = () => {
        navigate(-1)
    }

    const [user, setUser] = useState(null);

    const googleSignIn = () => {
        if (user != null) {
            Logout();
            return;
        }
        const auth = getAuth();
        //auth.languageCode = 'it';
        auth.useDeviceLanguage();
        provider.setCustomParameters({
            'login_hint': 'user@example.com'
        });
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                //const credential = GoogleAuthProvider.credentialFromResult(result);
                //const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                //subscribeToTopic(user.uid);
                // IdP data available using getAdditionalUserInfo(result)
                // console.log("User: ", user);
                // console.log("Token: ", token);
                // console.log("Credential: ", credential);
                setUser(user);
                //setLoginText("Logout");

                // ...
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                /*console.log(error);
                console.log("Error Code: ", errorCode);
                console.log("Error Message: ", errorMessage);
                console.log("Email: ", email);
                console.log("Credential: ", credential);*/
            });
    }

    useEffect(() => {
        const auth = getAuth();
        // This listener gets called whenever the user's sign-in state changes
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                // User is signed in
                setUser(currentUser);
                //subscribeToTopic(currentUser.uid);
                navigate('/home')
                //console.log('User infox:', currentUser, currentUser.displayName);
            } else {
                // User is signed out
                setUser(null);
                //console.log('User is signed out');
            }
        });

        // Clean up the subscription
        return () => unsubscribe();
    }, []);

    return (
        <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <GlobalStyle />
            <Container>
                <BackButton>
                    <FaArrowLeft onClick={goBack}/>
                </BackButton>
                <ThemeToggle onClick={toggleTheme}>
                    {isDarkMode ? <FaSun /> : <FaMoon />}
                </ThemeToggle>
                <LoginCard>
                    <Logo src={logo} alt="BreatheIn.me Logo" />
                    <Title>BreatheIn.me</Title>
                    <Subtitle>AI Therapist for Mental Health Support</Subtitle>
                    <Button onClick={googleSignIn}>
                        <FaGoogle style={{ marginRight: '10px' }} />
                        Login with Google
                    </Button>
                </LoginCard>
            </Container>
        </ThemeProvider>
    )
}