import React from 'react'
import styled from 'styled-components'
import { FiArrowRight } from 'react-icons/fi'
import {useNavigate} from "react-router-dom";

const CTAWrapper = styled.section`
  padding: 4rem 2rem;
  background: linear-gradient(135deg, ${props => props.theme.primary}22, ${props => props.theme.secondary}22);
  text-align: center;
`

const Title = styled.h2`
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: ${props => props.theme.text};
    font-family: "Caveat", sans-serif;
`

const CTAButton = styled.button`
  background: linear-gradient(135deg, ${props => props.theme.primary}, ${props => props.theme.secondary});
  color: #ffffff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 50px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-3px);
  }
`

export default function CallToActionSection() {
    const navigate = useNavigate();

    const handleGetStartedClick = () => {
        navigate("/login");
    };
    return (
        <CTAWrapper>
            <Title>Get Started with BreatheIn</Title>
            <CTAButton onClick={handleGetStartedClick}>
                Sign Up For Free <FiArrowRight />
            </CTAButton>
        </CTAWrapper>
    )
}