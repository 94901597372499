import React from 'react'
import styled from 'styled-components'
import {FiCalendar, FiHome, FiSettings} from 'react-icons/fi'
import {RiMentalHealthLine} from 'react-icons/ri'

const NavBarWrapper = styled.nav`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: calc(100% - 30px);
    background: ${props => props.theme.surface};
    backdrop-filter: blur(10px);
    border-radius: 16px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    margin-left: 15px;
    margin-right: 15px;
`

const NavItem = styled.button<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    color: ${props => props.active ? props.theme.primary : props.theme.text};
    text-decoration: none;
    border-radius: 8px;
    padding: 0.5rem;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }

    svg {
        font-size: 1.5rem;
        margin-bottom: 0.25rem;
    }
`

const NavText = styled.span`
    font-size: 0.75rem;
`

interface NavBarProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
}

export const NavBar: React.FC<NavBarProps> = ({activeTab, setActiveTab}) => {
    return (
        <NavBarWrapper>
            <NavItem active={activeTab === 'home'} onClick={() => setActiveTab('home')}>
                <FiHome/>
                <NavText>Home</NavText>
            </NavItem>
            <NavItem active={activeTab === 'sessions'} onClick={() => setActiveTab('sessions')}>
                <FiCalendar/>
                <NavText>Sessions</NavText>
            </NavItem>
            <NavItem active={activeTab === 'wellness'} onClick={() => setActiveTab('wellness')}>
                <RiMentalHealthLine/>
                <NavText>Wellness</NavText>
            </NavItem>
            <NavItem active={activeTab === 'settings'} onClick={() => setActiveTab('settings')}>
                <FiSettings/>
                <NavText>Settings</NavText>
            </NavItem>
        </NavBarWrapper>
    )
}