export const BASE_URL = "https://api.breathein.me";

//export const BASE_URL = " https://780c-2601-646-8b85-5520-1501-fd4-81b2-8220.ngrok-free.app";

export async function uploadFile(blob, filename = "recording.wav") {
    try {
        // Prepare FormData with the file blob
        const formData = new FormData();
        formData.append("file", blob, filename);

        // Upload the file
        const uploadResponse = await fetch("https://tmpfiles.org/api/v1/upload", {
            method: "POST",
            body: formData,
        });

        if (!uploadResponse.ok) {
            throw new Error('Network response was not ok when uploading the file.');
        }

        const uploadResult = await uploadResponse.json();
        let link = uploadResult.data?.url;
        if (link) {
            link = link.replace("https://tmpfiles.org/", "https://tmpfiles.org/dl/");
        }

        //console.log(`File Size: ${blob.size}`); // Log the file size
        return link;
    } catch (error) {
        console.error("Error in uploadFile:", error);
        return null;
    }
}

export async function requestTherapy(uid, audioUrl, setAudioUrl, sessionId, setSendingRequest, setShowLoadingDialog) {
    setShowLoadingDialog(true);
    /*if (true) {
        console.log("RequestTherapy", uid, audioUrl, sessionId);
        setSendingRequest(false);
        setAudioUrl(null);
        setTimeout(() => {
            setShowLoadingDialog(false);
        }, 2000);
        return;
    }*/
    //console.log("RequestTherapy", uid);
    const body = {
        uid: uid,
        audioUrl: audioUrl,
        sessionId: sessionId
    };
    //console.log("RequestTherapy", body);

    fetch(`${BASE_URL}/therapist`, { // Assuming flagModel.baseUrl is accessible
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'bypass-tunnel-reminder': 'true'
        },
        body: JSON.stringify(body)
    }).then(response => {
        setAudioUrl(null);
        setSendingRequest(false);
        setShowLoadingDialog(false);
        if (!response.ok) {
            //throw new Error('Network response was not ok.');
            return; // Return an empty array in case of error
        }

        //const jsonResponse = response.json();
        // Assuming you want to log the suggestions as in the Kotlin version's onResponse log
        //console.log('RequestTherapy', jsonResponse);
    }).catch(error => {
        setAudioUrl(null);
        setSendingRequest(false);
        setShowLoadingDialog(false);
        // Assuming you want to log errors as in the Kotlin version's onError
        console.error('RequestTherapy', error);
    });
}

export function getSessions(uid, setSessions) {
    //console.log('Getting sessions for user:', uid);
    try {
        const body = {
            uid: uid
        };
        fetch(`${BASE_URL}/get-sessions`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'bypass-tunnel-reminder': 'true',
            },
            body: JSON.stringify(body),
        }).then(response => {
            if (!response.ok) {
                setSessions([]);
                console.log("AnswerBot Response:", response.json().then(data => console.log(data)));
                //throw new Error('Network response was not ok.');
            }

            response.json().then(result => {
                // Replicate the first array 20 times to simulate loads of data
                //result = Array(20).fill(result).flat();
                setSessions(result);
                //console.log("AnswerBot Response:", result);
                //listener(result);
            }, error => {
                setSessions([]);
                console.error("Error in answerBot:", error);
                //listener(null);
            });

            // Assuming you have a logging mechanism similar to `.log()` in Kotlin
            //console.log("AnswerBot Response:", result);
            //listener(result);
        }, error => {
            setSessions([]);
            console.error("Error in answerBot:", error);
            //listener(null);
        });
    } catch (error) {
        setSessions([]);
        console.error("Error in answerBot:", error);
        //listener(null);
        return null;
    }
}

export function create_session(uid, setCreatedSession, doctor, prompt, setShowLoadingDialog) {
    /*if (true) {
        console.log("Create Session:", uid, doctor, prompt);
        return;
    }*/
    setShowLoadingDialog(true);
    try {
        const body = {
            uid: uid,
            doctorName: doctor?.name,
            doctorImage: doctor?.image,
            doctorMode: doctor?.mode,
            prompt: prompt
        };
        fetch(`${BASE_URL}/create-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'bypass-tunnel-reminder': 'true',
            },
            body: JSON.stringify(body),
        }).then(response => {
            if (!response.ok) {
                setCreatedSession(null);
                console.log("AnswerBot Response:", response.json().then(data => console.log(data)));
                //throw new Error('Network response was not ok.');
            }

            response.json().then(result => {
                setShowLoadingDialog(false);
                setCreatedSession(result);
                //console.log("AnswerBot Response:", result);
                //listener(result);
            }, error => {
                setShowLoadingDialog(false);
                setCreatedSession(null);
                //console.error("Error in answerBot:", error);
                //listener(null);
            });
            // Assuming you have a logging mechanism similar to `.log()` in Kotlin
            //console.log("AnswerBot Response:", result);
            //listener(result);
        }, error => {
            setShowLoadingDialog(false);
            setCreatedSession(null);
            //console.error("Error in answerBot:", error);
            //listener(null);
        });
    } catch (error) {
        setCreatedSession(null);
        console.error("Error in answerBot:", error);
        //listener(null);
        return null;
    }
}

export function delete_session(uid, sessionId, setDeletedSession) {
    //console.log("Delete Session:", uid, sessionId, setDeletedSession);
    try {
        const body = {
            uid: uid,
            sessionId: sessionId
        };
        fetch(`${BASE_URL}/delete-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'bypass-tunnel-reminder': 'true',
            },
            body: JSON.stringify(body),
        }).then(response => {
            if (!response.ok) {
                setDeletedSession(null);
                //console.log("AnswerBot Response:", response.json().then(data => console.log(data)));
                //throw new Error('Network response was not ok.');
            }

            response.json().then(result => {
                setDeletedSession(result);
                //console.log("AnswerBot Response:", result);
                //listener(result);
            }, error => {
                setDeletedSession(null);
                console.error("Error in answerBot:", error);
                //listener(null);
            });

            // Assuming you have a logging mechanism similar to `.log()` in Kotlin
            //console.log("AnswerBot Response:", result);
            //listener(result);
        }, error => {
            setDeletedSession(null);
            //console.error("Error in answerBot:", error);
            //listener(null);
        });
    } catch (error) {
        setDeletedSession(null);
        console.error("Error in answerBot:", error);
        //listener(null);
        return null;
    }
}

