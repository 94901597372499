import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { FiLoader } from 'react-icons/fi'

export enum DialogSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

interface LoadingAlertDialogProps {
    visible: boolean
    size: DialogSize
    message?: string
    onClose?: () => void
}

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const DialogOverlay = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const DialogContent = styled.div<{ size: DialogSize }>`
  background: rgba(74, 47, 110, 0.4);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #ffffff;

  ${props => {
    switch (props.size) {
        case DialogSize.SMALL:
            return css`
          width: 200px;
          height: 200px;
        `
        case DialogSize.LARGE:
            return css`
          width: 400px;
          height: 400px;
        `
        default:
            return css`
          width: 300px;
          height: 300px;
        `
    }
}}

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 70%
    );
    transform: rotate(30deg);
  }
`

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const StyledSpinner = styled(FiLoader)`
  animation: ${spin} 1s linear infinite;
  margin-bottom: 1rem;
`

const Message = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
`

export default function LoadingAlertDialog({
                                               visible,
                                               size,
                                               message = "Loading...",
                                               onClose
                                           }: LoadingAlertDialogProps) {
    return (
        <DialogOverlay visible={visible} onClick={onClose}>
            <DialogContent size={size} onClick={e => e.stopPropagation()}>
                <SpinnerWrapper>
                    <StyledSpinner size={size === DialogSize.SMALL ? 40 : size === DialogSize.LARGE ? 80 : 60} />
                    <Message>{message}</Message>
                </SpinnerWrapper>
            </DialogContent>
        </DialogOverlay>
    )
}