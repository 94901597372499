export function timeAgo(timeInMillis) {
    //console.log("Session: ", session);
    const seconds = Math.floor((new Date() - timeInMillis) / 1000);

    let interval = seconds / 31536000; // Number of seconds in a year

    if (interval > 1) {
        return `${Math.floor(interval)} year${Math.floor(interval) > 1 ? 's' : ''} ago`;
    }
    interval = seconds / 2592000; // Number of seconds in a month
    if (interval > 1) {
        return `${Math.floor(interval)} month${Math.floor(interval) > 1 ? 's' : ''} ago`;
    }
    interval = seconds / 604800; // Number of seconds in a week
    if (interval > 1) {
        return `${Math.floor(interval)} week${Math.floor(interval) > 1 ? 's' : ''} ago`;
    }
    interval = seconds / 86400; // Number of seconds in a day
    if (interval > 1) {
        return `${Math.floor(interval)} day${Math.floor(interval) > 1 ? 's' : ''} ago`;
    }
    interval = seconds / 3600; // Number of seconds in an hour
    if (interval > 1) {
        return `${Math.floor(interval)} hour${Math.floor(interval) > 1 ? 's' : ''} ago`;
    }
    interval = seconds / 60; // Number of seconds in a minute
    if (interval > 1) {
        return `${Math.floor(interval)} minute${Math.floor(interval) > 1 ? 's' : ''} ago`;
    }
    return `${Math.floor(seconds)} second${Math.floor(seconds) > 1 ? 's' : ''} ago`;
}

// Extract date in format "20 Mar 2021"
export function extractDate(timeInMillis) {
    let date = new Date(timeInMillis);
    return date.toDateString().substring(4);
}

// Get todays dat in format "20 Mar 2021"
export function getTodayDate() {
    let date = new Date();
    return date.toDateString().substring(4);
}

export function getRandomNPrompts(list, n) {
    let result = [];
    let count = 0;
    while (count < n) {
        let randomIndex = Math.floor(Math.random() * list.length);
        if (!result.includes(list[randomIndex])) {
            result.push(list[randomIndex]);
            count++;
        }
    }
    //console.log("Random Prompts: ", result);
    return result;
}