import styled, { ThemeProvider } from 'styled-components'
import { FiMoon, FiSun } from 'react-icons/fi'
import Header from '../components/landing/Header'
import HeroSection from '../components/landing/HeroSection'
import TrustedBySection from '../components/landing/TrustedBySection'
import TestimonialsSection from '../components/landing/TestimonialsSection'
import StatsSection from '../components/landing/StatsSection'
import StepsSection from '../components/landing/StepsSection'
import PricingSection from '../components/landing/PricingSection'
import FAQsSection from '../components/landing/FAQsSection'
import Footer from '../components/landing/Footer'
import {useEffect, useState} from "react";
import React from 'react'
import VideoDemoSection from "../components/landing/VideoDemoSection";
import CallToActionSection from "../components/landing/CallToActionSection";
import BlogSection from "../components/landing/BlogSection";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {useNavigate} from "react-router-dom";

const darkTheme = {
    background: '#121212',
    text: '#ffffff',
    primary: '#6200ee',
    secondary: '#03dac6',
    accent: '#bb86fc',
}

const lightTheme = {
    background: '#ffffff',
    text: '#121212',
    primary: '#6200ee',
    secondary: '#03dac6',
    accent: '#bb86fc',
}

const PageWrapper = styled.div`
    background-color: ${props => props.theme.background};
    color: ${props => props.theme.text};
    transition: all 0.3s ease;
    font-family: 'DinRegular', sans-serif;
    height: 100dvh;
`

const ThemeToggle = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.background};
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`

export default function Landing() {
    const [isDarkMode, setIsDarkMode] = useState(true)
    const navigate = useNavigate()

    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode)
    }

    useEffect(() => {
        const auth = getAuth();
        // This listener gets called whenever the user's sign-in state changes
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                // User is signed in
                navigate('/home')
                //console.log('User infox:', currentUser, currentUser.displayName);
            }
        });

        // Clean up the subscription
        return () => unsubscribe();
    }, []);

    return (
        <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <PageWrapper>
                <Header />
                <HeroSection />
                <VideoDemoSection />
                <TrustedBySection />
                <TestimonialsSection />
                <StatsSection />
                <StepsSection />
                <PricingSection />
                <FAQsSection />
                <CallToActionSection />
                <BlogSection />
                <Footer />
                <ThemeToggle onClick={toggleTheme}>
                    {isDarkMode ? <FiSun size={24} /> : <FiMoon size={24} />}
                </ThemeToggle>
            </PageWrapper>
        </ThemeProvider>
    )
}