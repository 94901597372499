import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FiChevronLeft, FiChevronRight, FiStar } from 'react-icons/fi'

// Define the structure of the fetched data
interface Testimonial {
    name: string
    quote: string
    avatar: string
}

interface Theme {
    primary: string
    secondary: string
    text: string
    background: string
}

const TestimonialsWrapper = styled.section<{ theme: Theme }>`
    padding: 4rem 2rem;
    background: linear-gradient(135deg, ${(props) => props.theme.primary}11, ${(props) => props.theme.secondary}11);
`

const Title = styled.h2<{ theme: Theme }>`
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: ${(props) => props.theme.text};
    font-family: "Caveat", sans-serif;
`

const TestimonialCard = styled.div<{ theme: Theme }>`
    background: ${(props) => props.theme.background}66;
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 2rem;
    margin: 0 1rem;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    text-align: center;
`

const Avatar = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 1rem;
`

const StarRating = styled.div<{ theme: Theme }>`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    color: gold;
`

const Quote = styled.p<{ theme: Theme }>`
    font-style: italic;
    margin-bottom: 1rem;
    color: ${(props) => props.theme.text};
`

const Name = styled.p<{ theme: Theme }>`
    font-weight: bold;
    color: ${(props) => props.theme.primary};
`

const SliderControls = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
`

const SliderButton = styled.button<{ theme: Theme }>`
    background: ${(props) => props.theme.primary};
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }
`

export default function TestimonialsSection() {
    const [testimonials, setTestimonials] = useState<Testimonial[]>([])
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const response = await fetch('https://randomuser.me/api/?results=5')
                const data = await response.json()
                const fetchedTestimonials = data.results.map((user: any, index: number) => ({
                    name: `${user.name.first} ${user.name.last}`,
                    quote: [
                        "BreatheIn.me has been a game-changer for my mental health journey. The AI therapist is incredibly intuitive and helpful.",
                        "I was skeptical at first, but BreatheIn.me has truly helped me manage my anxiety. It's like having a therapist in your pocket!",
                        "The personalized insights and daily check-ins have made a significant difference in my overall well-being. Highly recommended!",
                        "I've tried other mental health apps, but BreatheIn.me is by far the best. The AI therapist feels like a real person who cares about you.",
                        "I've been using BreatheIn.me for a few weeks now, and I can already see a positive change in my mood and outlook on life.",
                    ][index],
                    avatar: user.picture.large,
                }))
                setTestimonials(fetchedTestimonials)
            } catch (error) {
                console.error('Error fetching testimonials:', error)
            }
        }

        fetchTestimonials()
    }, [])

    const nextTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length)
    }

    const prevTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length)
    }

    if (testimonials.length === 0) {
        return null
    }

    const currentTestimonial = testimonials[currentIndex]

    return (
        <TestimonialsWrapper>
            <Title>What Our Users Say</Title>
            <TestimonialCard>
                <Avatar src={currentTestimonial.avatar} alt={currentTestimonial.name} />
                <StarRating>
                    {[...Array(5)].map((_, i) => (
                        <FiStar key={i} size={24} fill="gold"/>
                    ))}
                </StarRating>
                <Quote>"{currentTestimonial.quote}"</Quote>
                <Name>{currentTestimonial.name}</Name>
            </TestimonialCard>
            <SliderControls>
                <SliderButton onClick={prevTestimonial} aria-label="Previous testimonial">
                    <FiChevronLeft />
                </SliderButton>
                <SliderButton onClick={nextTestimonial} aria-label="Next testimonial">
                    <FiChevronRight />
                </SliderButton>
            </SliderControls>
        </TestimonialsWrapper>
    )
}
