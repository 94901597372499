import React, { useState } from 'react'
import styled from 'styled-components'
import { FiSmile, FiMeh, FiFrown, FiTrendingUp, FiActivity } from 'react-icons/fi'
import { GlassCard, CardTitle, Button } from '../SharedComponents'
import {darkTheme} from "../Theme";

const WellnessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 100px;
`

const MoodTracker = styled(GlassCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const MoodButtons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 1rem;
`

const MoodButton = styled(Button)<{ selected: boolean }>`
  flex-direction: column;
  align-items: center;
  background: ${props => props.selected ? props.theme.primary : 'transparent'};
  color: ${props => props.selected ? '#ffffff' : props.theme.text};

  svg {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
`

const ProgressChart = styled(GlassCard)`
  width: 100%;
  height: 300px;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const WellnessGoals = styled(GlassCard)`
  width: 100%;
  margin-top: 2rem;
`

const GoalList = styled.ul`
  list-style-type: none;
  padding: 0;
`

const GoalItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid ${props => props.theme.primary}33;

  &:last-child {
    border-bottom: none;
  }
`

const GoalText = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const GoalProgress = styled.div`
  width: 100px;
  height: 10px;
  background: ${props => props.theme.surface};
  border-radius: 5px;
  overflow: hidden;
`

const GoalProgressBar = styled.div<{ progress: number }>`
  width: ${props => props.progress}%;
  height: 100%;
  background: ${props => props.theme.primary};
`

export const Wellness: React.FC = () => {
  const [mood, setMood] = useState<string | null>(null)

  const goals = [
    { id: 1, text: 'Meditate for 10 minutes daily', progress: 70 },
    { id: 2, text: 'Practice gratitude journaling', progress: 50 },
    { id: 3, text: 'Exercise for 30 minutes 3 times a week', progress: 30 },
  ]

  return (
    <WellnessWrapper>
      <MoodTracker>
        <CardTitle>How are you feeling today?</CardTitle>
        <MoodButtons>
          <MoodButton selected={mood === 'happy'} onClick={() => setMood('happy')}>
            <FiSmile />
            Happy
          </MoodButton>
          <MoodButton selected={mood === 'neutral'} onClick={() => setMood('neutral')}>
            <FiMeh />
            Neutral
          </MoodButton>
          <MoodButton selected={mood === 'sad'} onClick={() => setMood('sad')}>
            <FiFrown />
            Sad
          </MoodButton>
        </MoodButtons>
      </MoodTracker>

      <ProgressChart>
        <CardTitle>Your Mood Trends</CardTitle>
        {/* Add a chart component here to visualize mood trends */}
        <FiTrendingUp size={100} color={darkTheme.primary} />
      </ProgressChart>

      <WellnessGoals>
        <CardTitle>Wellness Goals</CardTitle>
        <GoalList>
          {goals.map(goal => (
            <GoalItem key={goal.id}>
              <GoalText>
                <FiActivity />
                {goal.text}
              </GoalText>
              <GoalProgress>
                <GoalProgressBar progress={goal.progress} />
              </GoalProgress>
            </GoalItem>
          ))}
        </GoalList>
      </WellnessGoals>
    </WellnessWrapper>
  )
}