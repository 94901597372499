import React from 'react'
import styled from 'styled-components'
import { GlassCard, CardTitle } from './SharedComponents'

export const WellnessCheck: React.FC = () => {
  return (
    <GlassCard>
      <CardTitle>Quick Wellness Check</CardTitle>
      <p>How are you feeling today?</p>
      {/* Add mood selection component here */}
    </GlassCard>
  )
}