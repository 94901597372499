import React from 'react'
import { GlassCard, CardTitle } from './SharedComponents'

export const AIInsights: React.FC = () => {
  return (
    <GlassCard>
      <CardTitle>AI Therapist Insights</CardTitle>
      <p>Based on your recent sessions, here are some personalized tips:</p>
      {/* Add AI-generated insights here */}
    </GlassCard>
  )
}