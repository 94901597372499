import React, {useState} from 'react'
import styled from 'styled-components'
import {FiCalendar, FiClock, FiTag, FiTrash2} from 'react-icons/fi'
import {Button, CardTitle, GlassCard} from '../SharedComponents'
import {Session} from "../../../model/Session";
import {timeAgo} from "../../../utils/StringUtils";

const SessionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 85%;
    padding-bottom: 100px;
`

const SessionCard = styled(GlassCard)`
    display: flex;
    flex-direction: column;
    max-width: 600px;
`

const SessionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`

const SessionTitle = styled.h3`
    font-size: 1.2rem;
    color: ${props => props.theme.primary};
`

const SessionDate = styled.span`
    display: flex;
    align-items: center;
    color: ${props => props.theme.textSecondary};
    font-size: 0.9rem;
`

export const SessionContent = styled.p`
    margin-bottom: 1rem;
`

const SessionMeta = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SessionTags = styled.div`
    display: flex;
    gap: 0.5rem;
    max-width: 90%;
    flex-wrap: wrap;
`

const Tag = styled.span`
    background: ${props => props.theme.primary}33;
    color: ${props => props.theme.primary};
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
`

const SessionDuration = styled.span`
    color: ${props => props.theme.textSecondary};
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
`

const FilterBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
`

const FilterButton = styled(Button)`
    padding: 0.2rem 0.5rem;
    margin-left: 0.5rem;
    font-size: 0.9rem;

    @media (max-width: 768px) {
        font-size: 0.7rem;
    }
`

const SearchInput = styled.input`
    background: ${props => props.theme.surface};
    border: 1px solid ${props => props.theme.primary}33;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    color: ${props => props.theme.text};
    width: 200px;

    &::placeholder {
        color: ${props => props.theme.textSecondary};
    }
`

const DeleteButton = styled.button`
    background: none;
    border: none;
    color: ${props => props.theme.textSecondary};
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
        color: #ff4d4d;
    }
`

export interface SessionFragmentProps {
    sessions: Session[],
    startSession: (session: Session) => void;
    deleteClick: (session: Session) => void;
}

export const Sessions: React.FC<SessionFragmentProps> = ({sessions, startSession, deleteClick}) => {
    const [filter, setFilter] = useState('all')
    const [searchTerm, setSearchTerm] = useState('')

    /*const sessions = [
        {
            id: 1,
            title: 'Overcoming Anxiety',
            date: '2023-06-15',
            content: 'Discussed techniques for managing anxiety in social situations...',
            tags: ['Anxiety', 'Social'],
            duration: 45,
        },
        {
            id: 2,
            title: 'Building Self-Confidence',
            date: '2023-06-10',
            content: 'Explored methods to improve self-esteem and build confidence...',
            tags: ['Self-esteem', 'Confidence'],
            duration: 60,
        },
        // Add more sessions as needed
    ]*/

    const filteredSessions = sessions.filter(session => {
        if (filter !== 'all' && !session?.hashtags?.includes(filter)) return false
        if (searchTerm && !session?.title?.toLowerCase().includes(searchTerm?.toLowerCase())) return false
        return true
    })

    return (
        <SessionsWrapper>
            <CardTitle>Your Therapy Sessions</CardTitle>
            <FilterBar>
                <div>
                    <FilterButton onClick={() => setFilter('all')}>All</FilterButton>
                    {/*<FilterButton onClick={() => setFilter('Anxiety')}>Anxiety</FilterButton>
                    <FilterButton onClick={() => setFilter('Self-esteem')}>Self-esteem</FilterButton>*/}
                </div>
                <SearchInput
                    type="text"
                    placeholder="Search sessions..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </FilterBar>
            {filteredSessions.map(session => (
                <SessionCard key={session.id}>
                    <SessionHeader onClick={()=> startSession(session)}>
                        <SessionTitle>{session?.title ? session?.title : 'Untitled Session'}</SessionTitle>
                        <SessionDate>
                            <FiCalendar style={{marginRight: '0.5rem'}}/>
                            {timeAgo(session.updated)}
                        </SessionDate>
                    </SessionHeader>
                    <SessionContent>{session?.description ? session?.description : 'Tap To Start Talking'}</SessionContent>
                    <SessionMeta>
                        <SessionTags onClick={()=> startSession(session)}>
                            {session?.hashtags?.map(tag => (
                                <Tag key={tag}>
                                    <FiTag/>
                                    {tag}
                                </Tag>
                            ))}
                        </SessionTags>
                        {/*<SessionDuration>
                            <FiClock/>
                            {session.duration} min
                        </SessionDuration>*/}
                        <DeleteButton onClick={() => deleteClick(session)}><FiTrash2/></DeleteButton>
                    </SessionMeta>
                </SessionCard>
            ))}
        </SessionsWrapper>
    )
}