import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FaPlus, FaTimes } from 'react-icons/fa';
import {Doctor, doctorData} from "../model/Session";
import {useToast} from "./ToastProvider";
import {UseToast} from "../screens/dashboard";

const glowAnimation = keyframes`
    0% { box-shadow: 0 0 5px rgba(138, 43, 226, 0.7); }
    50% { box-shadow: 0 0 20px rgba(138, 43, 226, 0.9); }
    100% { box-shadow: 0 0 5px rgba(138, 43, 226, 0.7); }
`;

const slideDown = keyframes`
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
`;

const slideUp = keyframes`
    from { transform: translateY(0); opacity: 1; }
    to { transform: translateY(-20px); opacity: 0; }
`;

const rotateIcon = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(180deg); }
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background: transparent;
    position: fixed;
    bottom: 110px; // Adjust based on your navbar height
    z-index: 1001;
`;

const StyledButton = styled.button`
    background: linear-gradient(45deg, #8a2be2, #4b0082);
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 18px;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    animation: ${glowAnimation} 2s infinite;
    z-index: 10;

    &:hover {
        transform: scale(1.05);
    }

    svg {
        margin-right: 10px;
        transition: transform 0.3s ease;
    }
`;

const CardsContainer = styled.div<{ isVisible: boolean}>`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: ${props => props.isVisible ? 'auto' : '0'};
    overflow: hidden;
    transition: height 0.3s ease-out;
`;

const DoctorGlassCard = styled.div<{ isVisible: boolean; hasClicked: boolean, clickedDoctor: Boolean }>`
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 20px;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.18);
    opacity: ${props => props.isVisible ? 1 : 0};
    transform: translateY(${props => props.isVisible ? '0' : '-20px'});
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
    animation: ${props => props.isVisible && props.hasClicked ? css`${slideDown} 0.3s ease-out` : 'none'};
    pointer-events: ${props => !props.clickedDoctor ? 'auto' : 'none'};
    cursor: ${props => props.clickedDoctor ? 'not-allowed' : 'pointer'};

    &:nth-child(1) { transition-delay: 0.1s; }
    &:nth-child(2) { transition-delay: 0.2s; }
    &:nth-child(3) { transition-delay: 0.3s; }
`;

const DoctorImage = styled.div<{imageUrl: string}>`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #4b0082;
    margin-bottom: 10px;
    background-image: url(${props => props.imageUrl});
    background-size: cover;
    background-position: center;
`;

const DoctorText = styled.p<{ mode: string }>`
    font-size: 16px;
    margin: 5px 0 0;
    font-weight: bold;
    background: ${props => {
        switch (props.mode) {
            case 'Gentle': return 'linear-gradient(45deg, #4CAF50, #8BC34A)';
            case 'Moderate': return 'linear-gradient(45deg, #FFC107, #FF9800)';
            case 'Blunt': return 'linear-gradient(45deg, #FF5722, #F44336)';
            default: return 'white';
        }
    }};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const StyledFaPlus = styled(FaPlus)`
    animation: ${rotateIcon} 0.3s ease-out reverse;
`;

const StyledFaTimes = styled(FaTimes)`
    animation: ${rotateIcon} 0.3s ease-out;
`;

export interface FuturisticButtonProps {
    doctorClick: (doctor: Doctor) => void;
}

export default function FuturisticButton({ doctorClick }: FuturisticButtonProps) {
    const [showCards, setShowCards] = useState(false);
    const [hasClicked, setHasClicked] = useState(false);
    const [clickedDoctor, setClickedDoctor] = useState<Boolean>(false);
    const {showToast} = useToast() as UseToast

    const handleClick = () => {
        setShowCards(!showCards);
        if (!hasClicked) {
            setHasClicked(true);
        }
    };

    const handleDoctorClick = (doctor: Doctor) => {
        doctorClick(doctor);
        setClickedDoctor(true);
        showToast({
            title: `${doctor.name} Selected`,
            description: `You have selected [${doctor.name} - ${doctor.mode}] for your session.`,
            timeout: 3000
        })
        setShowCards(false);
        setTimeout(() => {
            setClickedDoctor(false);
        }, 3000);
    }

    return (
        <ButtonWrapper>
            <CardsContainer isVisible={showCards}>
                {doctorData.map((doctor, index) => (
                    <DoctorGlassCard key={index} isVisible={showCards} onClick={()=> handleDoctorClick(doctor)} hasClicked={hasClicked} clickedDoctor={clickedDoctor}>
                        <DoctorImage imageUrl={doctor.image}/>
                        <DoctorText mode={doctor.mode}>{doctor.name}</DoctorText>
                        <DoctorText mode={doctor.mode}>{doctor.mode}</DoctorText>
                    </DoctorGlassCard>
                ))}
            </CardsContainer>
            <StyledButton onClick={handleClick}>
                {showCards ? <StyledFaTimes /> : <StyledFaPlus />}
                Start New Session
            </StyledButton>
        </ButtonWrapper>
    );
}