import styled from 'styled-components'
import { FiUsers, FiClock, FiSmile } from 'react-icons/fi'
import React from "react";

const StatsWrapper = styled.section`
  padding: 4rem 2rem;
  background: linear-gradient(135deg, ${props => props.theme.secondary}11, ${props => props.theme.primary}11);
`

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`

const StatCard = styled.div`
  background: ${props => props.theme.background}66;
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
`

const StatIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.primary};
`

const StatNumber = styled.h3`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  background: linear-gradient(135deg, ${props => props.theme.primary}, ${props => props.theme.secondary});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const StatLabel = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.text};
`

export default function StatsSection() {
    const stats = [
        { icon: <FiUsers />, number: '100,000+', label: 'Active Users' },
        { icon: <FiClock />, number: '1,000,000+', label: 'Sessions Completed' },
        { icon: <FiSmile />, number: '95%', label: 'Satisfaction Rate' },
    ]

    return (
        <StatsWrapper>
            <StatsGrid>
                {stats.map((stat, index) => (
                    <StatCard key={index}>
                        <StatIcon>{stat.icon}</StatIcon>
                        <StatNumber>{stat.number}</StatNumber>
                        <StatLabel>{stat.label}</StatLabel>
                    </StatCard>
                ))}
            </StatsGrid>
        </StatsWrapper>
    )
}