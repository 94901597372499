import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {FiPause, FiPlay} from 'react-icons/fi'

const VideoDemoWrapper = styled.section`
    padding: 4rem 2rem;
    background: ${props => props.theme.background};
`

const Title = styled.h2`
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: ${props => props.theme.text};
    font-family: "Caveat", sans-serif;
`

const TabContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
`

const Tab = styled.button<{ active: boolean }>`
    background: ${props => props.active ? props.theme.primary : 'transparent'};
    color: ${props => props.active ? '#ffffff' : props.theme.text};
    border: none;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 20px;

    &:hover {
        background: ${props => props.theme.primary}88;
        color: #ffffff;
    }
`

const VideoContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    border: 2px solid ${props => props.theme.primary}33;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(135deg, ${props => props.theme.primary}33, ${props => props.theme.secondary}33);
        z-index: 1;
        pointer-events: none;
    }
`

const Video = styled.video`
    width: 100%;
    display: block;
`

// Styled components for the buttons
const PlayButton = styled.button`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${props => props.theme.primary};
    color: #ffffff;
    border: none;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    cursor: pointer;
    z-index: 2;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background: ${props => props.theme.secondary};
        transform: translate(-50%, -50%) scale(1.1);
    }
`

const PauseButton = styled.button`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${props => props.theme.primary};
    color: #ffffff;
    border: none;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    cursor: pointer;
    z-index: 2;
    transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: ${props => props.theme.secondary};
  }
`

export default function VideoDemoSection() {
    const [activeTab, setActiveTab] = useState('Therapy Session')
    const [isPlaying, setIsPlaying] = useState(false)
    const [opacity, setOpacity] = useState(1) // Manage button opacity state
    const videoRef = useRef<HTMLVideoElement>(null)

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause()
                setIsPlaying(false)
                setOpacity(1) // Set opacity to 100% when paused
            } else {
                videoRef.current.play()
                setIsPlaying(true)
                setOpacity(1) // Set opacity to 100% when playing starts
            }
        }
    }

    useEffect(() => {
        if (isPlaying) {
            // Set opacity to 50% after 2 seconds
            const timer = setTimeout(() => {
                setOpacity(0.5)
            }, 2000)

            return () => clearTimeout(timer) // Cleanup timer on unmount or pause
        }
    }, [isPlaying])

    const tabs = ['Therapy Session', 'Session Notes', 'Progress Reports']
    const video1 = 'https://docs.material-tailwind.com/demo.mp4'
    const video2 = 'https://d3x4b1wy4qlu9.cloudfront.net/videos/hero/hero-collaborate.mp4'

    return (
        <VideoDemoWrapper id={`features`}>
            <Title>See BreatheIn in Action</Title>
            <TabContainer>
                {tabs.map(tab => (
                    <Tab
                        key={tab}
                        active={activeTab === tab}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab}
                    </Tab>
                ))}
            </TabContainer>
            <VideoContainer>
                <Video ref={videoRef} src={video1} loop muted />

                {/* Show play button when video is not playing */}
                {!isPlaying && (
                    <PlayButton onClick={handlePlayPause}>
                        <FiPlay />
                    </PlayButton>
                )}

                {/* Show pause button when video is playing, with dynamic opacity */}
                {isPlaying && (
                    <PauseButton
                        onClick={handlePlayPause}
                        style={{ opacity }} // Apply dynamic opacity to the pause button
                        onMouseEnter={() => setOpacity(1)} // Set opacity to 100% on hover
                        onMouseLeave={() => isPlaying && setOpacity(0.3)} // Return to 50% opacity when hover ends and video is playing
                    >
                        <FiPause />
                    </PauseButton>
                )}
            </VideoContainer>
        </VideoDemoWrapper>
    )
}