"use client"

import React, {useEffect, useRef, useState} from 'react'
import styled, {ThemeProvider} from 'styled-components'
import Header from '../components/chat/Header'
import MessageBubble from '../components/chat/MessageBubble'
import AudioBubble from '../components/chat/AudioBubble'
import InputArea from '../components/chat/InputArea'
import {getAuth} from "firebase/auth"
import {useLocation, useNavigate} from "react-router-dom"
import {useToast} from "../components/ToastProvider"
import {requestTherapy, uploadFile} from "../utils/ApiManager"
import {UseToast} from "./dashboard"
import {getSessionMessages} from "../utils/FirebaseUtils"
import {isLastMessageFromUser, isLastMessageTooOld, Message} from "../model/Message"
import LoadingAlertDialog, {DialogSize} from "../components/LoadingAlertDialog";

interface ThemeType {
    mode: 'dark' | 'light'
}

const AppContainer = styled.div<{ theme: ThemeType }>`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: ${({theme}) => theme.mode === 'dark'
            ? 'linear-gradient(135deg, #2a0845 0%, #1a1a2e 100%)'
            : 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)'};
    color: ${({theme}) => theme.mode === 'dark' ? '#fff' : '#333'};
`

const ChatContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 60px 20px 100px 20px;
    display: flex;
    flex-direction: column;
    margin-top: 60px;

    &::-webkit-scrollbar {
        display: none;
    }
`
const auth = getAuth();
export default function ChatInterface() {
    const [isRecording, setIsRecording] = useState(false)
    const [theme, setTheme] = useState<ThemeType>({mode: 'dark'})
    const [recordingTime, setRecordingTime] = useState(0)
    const [audioUrl, setAudioUrl] = useState<string | null>(null)
    const [isUploading, setIsUploading] = useState(false)
    const mediaRecorderRef = useRef<MediaRecorder | null>(null)
    const streamRef = useRef<MediaStream | null>(null)
    const audioChunksRef = useRef<Blob[]>([])
    const messagesEndRef = useRef<HTMLDivElement>(null)
    const [messages, setMessages] = useState<Message[]>([])
    const [showLoadingDialog, setShowLoadingDialog] = useState(false)
    const [sendingRequest, setSendingRequest] = useState(false)
    const [isCanceled, setIsCanceled] = useState(false)
    const [isCanceledText, setIsCanceledText] = useState('')
    const session = useLocation()?.state?.session
    const navigate = useNavigate()
    const {showToast} = useToast() as UseToast

    const getMessages = () => getSessionMessages(session?.id, setMessages)

    const goBack = () => navigate(-1)

    useEffect(() => {
        getMessages()
    }, [session?.id])

    useEffect(() => {
        setTimeout(() => {
            //console.log(`isCanceled: ${isCanceledText} audioChunksRef.current.length: ${audioChunksRef.current.length}`);
            if (isCanceledText.startsWith('true') || audioChunksRef.current.length === 0) {
                //console.log('Recording was canceled. Clearing audio chunks...');
                audioChunksRef.current = []; // Clear the chunks if canceled
                return
            }
            saveAudio()
        }, 800)
    }, [isCanceledText]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({behavior: "auto"})
        setIsRecording(false)
        //console.log('isLastMessageTooOld(messages, 2): ', isLastMessageTooOld(messages, 2))
        if (isLastMessageFromUser(messages) && !isLastMessageTooOld(messages, 2)) {
            setShowLoadingDialog(true)
        } else {
            setShowLoadingDialog(false)
        }
    }, [messages])

    useEffect(() => {
        //console.log('Audio URL:', audioUrl, 'Sending Request:', sendingRequest, 'UID:', auth.currentUser?.uid, 'Canceled:', isCanceled)
        if (!audioUrl || !auth.currentUser?.uid || sendingRequest || isCanceled) return; // Ensure isCanceled prevents further uploads

        setSendingRequest(true);
        requestTherapy(auth.currentUser.uid, audioUrl, setAudioUrl, session?.id, setSendingRequest, setShowLoadingDialog).finally(() => {
            // Reset the isCanceled flag here to prevent subsequent passes of canceled uploads
            setIsCanceled(false);
            setIsCanceledText('false' + Date.now().toString());
        });
    }, [audioUrl, sendingRequest, auth.currentUser?.uid, session?.id]); // Do not include isCanceled as dependency

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined
        if (isRecording) {
            interval = setInterval(() => {
                setRecordingTime(prev => prev >= 599.9 ? (stopRecording(true), prev) : prev + 0.1)
            }, 100)
        }
        return () => clearInterval(interval)
    }, [isRecording])

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true})
            streamRef.current = stream
            mediaRecorderRef.current = new MediaRecorder(stream)

            // Clear the audioChunks array when a new recording starts
            audioChunksRef.current = []

            // Push chunks of audio data to audioChunksRef as they become available
            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data)
                }
            }

            mediaRecorderRef.current.onstop = async () => {
                // If the recording was canceled, do not save the audio
                /*if (isCanceled) {
                    audioChunksRef.current = []; // Clear the chunks to avoid any potential issues
                    console.log('Recording was canceled. Skipping save.');
                    return;
                }*/

                /*// Set a small delay to ensure all data is captured
                setTimeout(() => {
                    console.log('Recording stopped, preparing to save audio...');
                    if (audioChunksRef.current.length > 0 && !isCanceled) {  // Double-check that recording wasn't canceled
                        saveAudio(); // Call saveAudio only if not canceled and chunks are available
                    }
                }, 100); // Small delay to ensure all chunks are processed*/
            };

            mediaRecorderRef.current.start()
            setIsRecording(true)
            setRecordingTime(0)
        } catch (error) {
            console.error('Error starting recording:', error)
        }
    }

    const stopRecording = (save: boolean) => {
        //console.log('Stopping recording, save:', save, 'isCanceled:', !save);
        setIsRecording(false);
        setIsCanceled(!save); // Set cancel state based on whether saving or not
        setIsCanceledText(!save.toString() + Date.now().toString());

        if (mediaRecorderRef.current?.state === 'recording') {
            if (!save) {
                //console.log('Clearing audio chunks, canceling recording...');
                audioChunksRef.current = []; // Clear the chunks if canceled
            }
            mediaRecorderRef.current.stop(); // Stop recording
            streamRef.current?.getTracks().forEach(track => track.stop());
        }

        /*if (!save) {
            console.log('Recording was canceled.');
            /!*setTimeout(() => {
                setIsCanceled(false);  // Reset cancel flag after short delay for future recordings
            }, 500);*!/
        }*/
    };

    const saveAudio = () => {
        //console.log('Saving audio... Length:', audioChunksRef.current.length, 'isCanceled:', isCanceled);
        if (isCanceled) {  // Short-circuit saving if canceled
            //console.log('Skipping audio save as recording was canceled.');
            audioChunksRef.current = [];  // Clear chunks in case of cancellation
            return;
        }

        const audioBlob = new Blob(audioChunksRef.current, {type: 'audio/wav'});
        //console.log('Saving audio... Blob size:', audioBlob.size, 'isUploading:', isUploading, 'isCanceled:', isCanceled);
        setIsRecording(false);

        if (audioBlob.size > 0 && !isUploading && !isCanceled) {  // Ensure isCanceled is checked
            if (isCanceled) {
                //console.log("Cancel flag is false, do not upload");
                setIsUploading(false);
                return; // Short-circuit saving if canceled
            }
            setIsUploading(true);
            /*if (true) {
                const random = Math.floor(Math.random() * 1000000);
                setAudioUrl('https://google.com' + random);
                setIsUploading(false);
                audioChunksRef.current = []; // Clear the chunks after uploading
                console.log('Audio uploaded successfully isCanceled:', isCanceled);
                return;
            }*/
            uploadFile(audioBlob)
                .then((uploadedUrl) => {
                    audioChunksRef.current = []; // Clear the chunks after uploading
                    setAudioUrl(uploadedUrl);
                })
                .finally(() => {
                    setIsUploading(false);
                    audioChunksRef.current = []; // Clear the chunks after uploading
                });
        } else {
            console.error('Audio Blob is empty or already uploading, skipping upload');
        }
    };

    const cancelRecording = () => {
        //console.log('Cancel recording')
        setIsCanceled(true) // Set the flag to indicate the recording was canceled
        stopRecording(false) // Call stopRecording but indicate that the recording should not be saved
    }

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60).toString().padStart(2, '0')
        const seconds = Math.floor(time % 60).toString().padStart(2, '0')
        const tenths = Math.floor((time * 10) % 10)
        return `${minutes}:${seconds}:${tenths}0`
    }

    const toggleTheme = () => setTheme(prev => ({mode: prev.mode === 'dark' ? 'light' : 'dark'}))

    return (
        <ThemeProvider theme={theme}>
            <AppContainer>
                <Header doctorImage={session?.doctorImage} doctorName={session?.doctorName} backClick={goBack} infoClick={() => showToast({
                    title: 'Clinical Notes',
                    description: 'These will be the notes from your current session. Coming soon!'
                })}/>
                <ChatContainer>

                    {messages?.length === 0 ? (
                        <div className={`no-messages`}>No messages yet</div>
                    ) : (
                        <>
                            {messages.map((value, index) => (
                                //console.log('ADAMX Message: ', value),
                                <div key={index}>
                                    {value?.isUser ? (
                                        value.audio && value.audio !== "None" ? (
                                            <AudioBubble audioUrl={value.audio} isUser={value?.isUser}
                                                         message={value.text}
                                                         theme={theme}/>
                                        ) : (
                                            <MessageBubble isUser={value?.isUser}>{value.text}</MessageBubble>
                                        )
                                    ) : (
                                        <MessageBubble isUser={value?.isUser}>{value.text}</MessageBubble>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                    <div ref={messagesEndRef}/>
                </ChatContainer>
                <InputArea
                    isRecording={isRecording}
                    toggleRecording={() => isRecording ? stopRecording(true) : startRecording()}
                    cancelRecording={() => cancelRecording()}
                    toggleTheme={toggleTheme}
                    recordingTime={formatTime(recordingTime)}
                    theme={theme.mode}
                    messages={messages}
                />
                <LoadingAlertDialog visible={showLoadingDialog} size={DialogSize.SMALL} message={'Thinking....'}/>
            </AppContainer>
        </ThemeProvider>
    )
}
