import React, {useEffect, useState} from 'react'
import styled, {ThemeProvider} from 'styled-components'
import {darkTheme, GlobalStyle, lightTheme} from '../components/dashboard/Theme'
import {Header} from '../components/dashboard/Header'
import {NavBar} from '../components/dashboard/NavBar'
import {Button} from '../components/dashboard/SharedComponents'
import {Sessions} from "../components/dashboard/tabs/Sessions";
import {Wellness} from "../components/dashboard/tabs/Wellness";
import {Home} from "../components/dashboard/tabs/Home";
import {Settings} from "../components/dashboard/tabs/Settings";
import {useToast} from "../components/ToastProvider";
import {getAuth, onAuthStateChanged, User} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {signOut} from "@firebase/auth";
import {create_session, delete_session, getSessions} from "../utils/ApiManager";
import {Doctor, doctorData, Session} from "../model/Session";
import {ConfirmDialog} from "../components/ConfirmDialog";
import FuturisticButton from "../components/FuturisticButton";
import LoadingAlertDialog, {DialogSize} from "../components/LoadingAlertDialog";

const Dashboard = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    background-color: ${props => props.theme.background};
    font-family: 'DinRegular', sans-serif;
`

const FixedHeader = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
`

const Content = styled.main`
    flex: 1;
    overflow-y: auto;
    padding: 1.8rem 1rem 1rem 1rem;
    margin-top: 60px; // Adjust based on your header height
    margin-bottom: 70px; // Adjust based on your navbar height
    display: flex;
    flex-direction: column;
    align-items: center;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`

const FixedNavBar = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
`

const ThemeToggle = styled.button`
    position: fixed;
    bottom: 115px; // Adjust based on your navbar height
    right: 1rem;
    background: ${props => props.theme.surface};
    backdrop-filter: blur(10px);
    border: none;
    color: ${props => props.theme.text};
    font-size: 1.5rem;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    z-index: 1001;

    &:hover {
        transform: scale(1.1);
    }
`

const FloatingButton = styled(Button)`
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 110px; // Adjust based on your navbar height
    z-index: 1001;
    align-self: center;
    font-size: 1.1rem;
    gap: 0.5rem;
    background: transparent;
`

export interface ToastOptions {
    title: string;
    description: string;
    actionText?: string | null;
    onAction?: () => void;
    timeout?: number;
}

export interface UseToast {
    showToast: (options: ToastOptions) => void;
}

export default function DashBoard() {
    const [theme, setTheme] = useState(darkTheme)
    const [activeTab, setActiveTab] = useState('home')
    const [sessions, setSessions] = useState([]);
    const [sessionHaveChanged, setSessionHaveChanged] = useState(false);
    const [doctor, setDoctor] = useState<Doctor | null>(null);
    const [prompt, setPrompt] = useState<string | null>(null);
    const [createdSession, setCreatedSession] = useState(null);
    const [user, setUser] = useState<User | null>(null);
    const [enableCreateSession, setEnableCreateSession] = useState(true);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [deleteSession, setDeleteSession] = useState(null);
    const [showLoadingDialog, setShowLoadingDialog] = useState(false);
    const [alertData, setAlertData] = useState({
        title: 'Are you absolutely sure?',
        message: 'This action cannot be undone. This will permanently delete your session and remove your data from our servers.',
        positiveText: 'Yes I\'m sure',
        negativeText: 'Cancel',
        positiveClick: () => {
            //console.log('Positive Click');
            setOpenConfirm(false);
        },
        negativeClick: () => {
            //console.log('Negative Click');
            setOpenConfirm(false);
        }
    });

    useEffect(() => {
        //console.log(`ADAMX Prompt: `, prompt);
        if (prompt == null) {
            return;
        }
        create_session(user?.uid, setCreatedSession, doctorData[0], prompt, setShowLoadingDialog);
        showToast({
            title: 'We are creating your session',
            description: 'Please wait a moment...',
            actionText: null,
            onAction: () => null,
            timeout: 3000
        });
        setTimeout(() => {
            setPrompt(null);
        }, 1000);
    }, [prompt]);

    const toggleTheme = () => {
        setTheme(theme.mode === 'dark' ? lightTheme : darkTheme)
    }

    const renderContent = () => {
        switch (activeTab) {
            case 'home':
                return <Home sessions={sessions} deleteClick={deleteThisSession} startSession={startSession}
                             setPrompt={setPrompt} user={user}/>
            case 'sessions':
                return <Sessions sessions={sessions} deleteClick={deleteThisSession} startSession={startSession}/>
            case 'wellness':
                return <Wellness/>
            case 'settings':
                return <Settings/>
            default:
                return <Home sessions={sessions} deleteClick={deleteThisSession} startSession={startSession}
                             setPrompt={setPrompt} user={user}/>
        }
    }
    const {showToast} = useToast() as UseToast; // Cast the hook return type
    const auth = getAuth();
    const navigate = useNavigate();

    function startSession(session: Session) {
        navigate('/call', {state: {session: session}});
    }

    const Logout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    }

    const handleToastClick = () => {
        showToast({
            title: 'Task Completed!',
            description: 'Your task has been successfully completed.',
            actionText: 'Undo',
            onAction: () => alert('Undo clicked!'),
        });
    };

    function createSession(doctor: Doctor) {
        //console.log(`Create Session`);
        setDoctor(doctor);
        if (!enableCreateSession) {
            return;
        }
        setEnableCreateSession(false);
        //Delay and re-enable
        setTimeout(() => {
            setEnableCreateSession(true);
        }, 3000);
        create_session(user?.uid, setCreatedSession, doctor, prompt, setShowLoadingDialog);
    }

    useEffect(() => {
        if (user?.uid == null) {
            //console.log(`No UID`);
            return;
        }
        getSessions(user?.uid, setSessions);
    }, [user]);

    useEffect(() => {
        // This listener gets called whenever the user's sign-in state changes
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                // User is signed in
                setUser(currentUser);
                //subscribeToTopic(currentUser.uid);
                navigate('/home')
                //console.log('User infox:', currentUser, currentUser.displayName);
            } else {
                // User is signed out
                setUser(null);
                //console.log('User is signed out');
                navigate('/');
            }
        });

        // Clean up the subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        //console.log(`ADAMX Sessions: `, sessions);
    }, [sessions]);

    useEffect(() => {
        if (createdSession == null) {
            return;
        }
        navigate('/call', {state: {session: createdSession, doctor: doctor}});
    }, [createdSession]);

    useEffect(() => {
        if (deleteSession == null) {
            return;
        }
        showToast({
            title: 'Session Deleted',
            description: 'Your session has been successfully deleted.',
            actionText: null,
            onAction: () => null,
        });
        //console.log(`Deleting Session: `, deleteSession);
    }, [deleteSession]);


    function deleteThisSession(session: Session) {
        //console.log(`Delete Session: `, session);
        setOpenConfirm(true);
        setAlertData({
            ...alertData, positiveClick: () => {
                delete_session(user?.uid, session.id, setDeleteSession);
                setOpenConfirm(false);
                getSessions(user?.uid, setSessions);
                setUser(user);
            },
            message: `This action cannot be undone. This will permanently delete "${session?.title}" session and remove your data from our servers.`
        })
    }

    function logOutThisSession() {
        setOpenConfirm(true);
        setAlertData({
            ...alertData, positiveClick: () => {
                Logout();
                setOpenConfirm(false);
            },
            message: `You are about to log out. Are you sure?`
        })
    }

    function showProfile() {
        showToast({
            title: 'Show Profile',
            description: 'This will show your profile. Coming soon!',
            actionText: null,
            onAction: () => null,
        });
    }

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle/>
            <Dashboard>
                <FixedHeader>
                    <Header logout={logOutThisSession} profile={showProfile}/>
                </FixedHeader>
                <Content>
                    {renderContent()}
                </Content>
                <FixedNavBar>
                    <NavBar activeTab={activeTab} setActiveTab={setActiveTab}/>
                </FixedNavBar>
                <FuturisticButton doctorClick={createSession}/>
                <ConfirmDialog open={openConfirm} setOpen={setOpenConfirm} alertData={alertData}/>
                <LoadingAlertDialog visible={showLoadingDialog} size={DialogSize.MEDIUM} message={'Creating Session....'}/>
                <ThemeToggle onClick={toggleTheme}>
                    {theme.mode === 'dark' ? '🌞' : '🌙'}
                </ThemeToggle>
            </Dashboard>
        </ThemeProvider>
    )
}