import React from 'react'
import styled from 'styled-components'
import {CardTitle, GlassCard} from './SharedComponents'
import {getRandomNPrompts} from "../../utils/StringUtils";

const PromptList = styled.ul`
    list-style: none;
    padding: 0;
`

const PromptItem = styled.li`
    background: ${props => props.theme.surface};
    backdrop-filter: blur(10px);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }
`

const prompts = [
    "I feel anxious, but I don't know why. Can you help me figure it out? 😰🤔",
    "I've been feeling really down lately, how can I start feeling better? 😞🌧️",
    "I'm having trouble sleeping. What can I do to improve my sleep? 😴🛏️",
    "I'm struggling with work-life balance. Any advice? 🏢🏡",
    "I’ve been feeling really stressed lately. Can you help me manage it? 😓🧠",
    "I’m not sure why I feel so overwhelmed all the time. Can you guide me through this? 😥💭",
    "I don’t feel motivated anymore. How can I get back on track? 😔🚶‍♂️",
    "I’ve been feeling lonely. How can I cope with this? 😞🤝",
    "I keep overthinking everything. How can I stop? 🤯🔄",
    "I feel lost and unsure about my future. Can you help me figure things out? 😕🔮",
    "I’ve been arguing a lot with my partner. How can I communicate better? 😡💬",
    "I struggle with self-confidence. How can I improve it? 🤦‍♂️💪",
    "I feel like I’m stuck in a rut. What can I do to change things? 😣🔄",
    "I’m having a hard time coping with a recent loss. Can you help me process it? 😢💔",
    "I keep procrastinating and it’s affecting my work. How can I overcome this? 🕰️💼",
    "I get anxious in social situations. How can I manage my social anxiety? 😬👥",
    "I’ve been feeling really burnt out. How can I recover? 😩🔥",
    "I want to improve my mental health but don’t know where to start. Can you help? 🧠🌱",
    "I feel guilty about something that happened. How can I forgive myself? 😔🙏",
    "I’m having trouble controlling my anger. What can I do to stay calm? 😡😌",
    "I often feel like I'm not good enough. How can I build my self-esteem? 😞🌟",
    "I’m finding it hard to set boundaries with people. Can you help me with that? 🛑🤝",
    "I’ve been dealing with a lot of negative thoughts. How can I reframe them? 😟🔄",
    "I have trouble staying focused and productive. Any tips on improving this? 🧠📈"
]

export interface SessionPromptsProps {
    setPrompt: (prompt: string | null) => void;
}

export const SessionPrompts: React.FC<SessionPromptsProps> = ({setPrompt}) => {
    const [promptList, setPromptList] = React.useState<string[]>(getRandomNPrompts(prompts, 5))
    const handlePromptClick = (prompt: string) => {
        //console.log(`Prompt clicked: ${prompt}`)
        setPrompt(prompt)
        //console.log(`Prompt set: ${prompt}`)
    }
    return (
        <GlassCard>
            <CardTitle>Start a New Session</CardTitle>
            <PromptList>
                {promptList.map((prompt, index) => (
                    <div onClick={() => handlePromptClick(prompt)} key={index}>
                        <PromptItem>{prompt}</PromptItem>
                    </div>
                ))}
            </PromptList>
        </GlassCard>
    )
}