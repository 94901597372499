import * as React from 'react';
import * as Toast from '@radix-ui/react-toast';
import '../styles/components/ToastProvider.css';

// Create context
const ToastContext = React.createContext(null);

// Provider component
export const ToastProvider = ({ children }) => {
    const [open, setOpen] = React.useState(false);
    const [toastContent, setToastContent] = React.useState({
        title: '',
        description: '',
        actionText: '',
        onAction: null,
        timeout: 3000,
    });
    const timerRef = React.useRef(0);

    React.useEffect(() => {
        return () => clearTimeout(timerRef.current);
    }, []);

    const showToast = ({ title, description, actionText, onAction, timeout = 3000 }) => {
        setOpen(false);
        window.clearTimeout(timerRef.current);
        timerRef.current = window.setTimeout(() => {
            setToastContent({ title, description, actionText, onAction, timeout });
            setOpen(true);
            timerRef.current = window.setTimeout(() => {
                setOpen(false);
            }, timeout);
        }, 100);
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            <Toast.Provider swipeDirection="right">
                <Toast.Root className="ToastRoot" open={open} onOpenChange={setOpen}>
                    <Toast.Title className="ToastTitle">{toastContent.title}</Toast.Title>
                    <Toast.Description asChild>
            <span className="ToastDescription">
              {toastContent.description}
            </span>
                    </Toast.Description>
                    {toastContent.actionText && (
                        <Toast.Action className="ToastAction" asChild altText="Toast Action Button">
                            <button className="Button small green" onClick={toastContent.onAction}>
                                {toastContent.actionText}
                            </button>
                        </Toast.Action>
                    )}
                </Toast.Root>
                <Toast.Viewport className="ToastViewport" />
            </Toast.Provider>
        </ToastContext.Provider>
    );
};

// Custom hook to use toast anywhere
export const useToast = () => {
    const context = React.useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};

