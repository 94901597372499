import {User} from "firebase/auth";

export type Session = {
    id: string;
    title: string;
    description: string;
    owner: string;
    created: number;
    updated: number;
    hashtags: string[];
    doctorName: string;
    doctorMode: string;
    doctorImage: string;
}

export type Doctor = {
    id: string;
    name: string;
    mode: string;
    image: string;
}

// Create sample doctor data
export const doctorData: DoctorData = [
    {
        id: '1',
        name: 'Dr. Emily',
        mode: 'Encouraging',
        image: 'https://cdn.midjourney.com/f12f7c4e-905d-4dc5-83c3-c8a950d8adda/0_0_384_N.webp?method=shortest&qst=6&quality=30'
    },
    /*{
        id: '2',
        name: 'Dr. Natasha',
        mode: 'Moderate',
        image: 'https://cdn.midjourney.com/0d60bb56-d67d-4f7c-a714-5a7032c0c4be/0_2_384_N.webp?method=shortest&qst=6&quality=30'
    },*/
    {
        id: '3',
        name: 'Dr. Karen',
        mode: 'Blunt',
        image: 'https://cdn.midjourney.com/58bc3a16-055c-4876-b4f9-a9a5a13d5876/0_1_384_N.webp?method=shortest&qst=6&quality=30'
    }
]
export type DoctorData = Doctor[];

export interface SessionListProps {
    sessions: Session[];
    deleteClick: (session: Session) => void;
    startSession: (session: Session) => void;
    user: User | null;
    setPrompt: (prompt: string | null) => void;
}

export function sortSessionsListsByTime(sessions: Session[]) {
    return sessions.sort((a, b) => a.updated - b.updated).reverse();
}

export function recentLastNSessions(sessions: Session[], n: number = 2) {

    return sortSessionsListsByTime(sessions).slice(0, n);
}
