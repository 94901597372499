import React from 'react'
import styled from 'styled-components'
import {FiLogOut, FiUser} from 'react-icons/fi'

const HeaderWrapper = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: ${props => props.theme.surface};
    backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`

const Logo = styled.h1`
    font-size: 1.8rem;
    color: ${props => props.theme.primary};
    margin: 0;
    font-family: Caveat, 'sans-serif';
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const IconButton = styled.button`
    background: none;
    border: none;
    color: ${props => props.theme.text};
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
        color: ${props => props.theme.primary};
    }
`

export interface HeaderProps {
    logout: () => void
    profile: () => void
}

export const Header: React.FC<HeaderProps> = ({logout, profile}) => {
    return (
        <HeaderWrapper>
            <IconButton onClick={profile}>
                <FiUser/>
            </IconButton>
            <Logo>BreatheIn</Logo>
            <IconButton onClick={logout}>
                <FiLogOut/>
            </IconButton>
        </HeaderWrapper>
    )
}