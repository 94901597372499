import React from 'react'
import styled from 'styled-components'
import {FiTrash2} from 'react-icons/fi'
import {CardTitle, GlassCard} from './SharedComponents'
import {recentLastNSessions, Session, SessionListProps} from "../../model/Session";
import {timeAgo} from "../../utils/StringUtils";
import {SessionContent} from "./tabs/Sessions";

const SessionListWrapper = styled.ul`
    list-style: none;
    padding: 0;
    width: 100%;
`

const SessionItem = styled.li`
    background: ${props => props.theme.surface};
    backdrop-filter: blur(10px);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 0.75rem;
`

const SessionTitle = styled.h4`
    font-weight: 500;
    margin: 0 0 0.5rem 0;
    font-size: 1.3rem;
`

const SessionHashtags = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
`

const Hashtag = styled.span`
    background: ${props => props.theme.primary}33;
    color: ${props => props.theme.primary};
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.75rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
`

const SessionMeta = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    color: ${props => props.theme.textSecondary};
`

const DeleteButton = styled.button`
    background: none;
    border: none;
    color: ${props => props.theme.textSecondary};
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
        color: #ff4d4d;
    }
`

export const SessionList: React.FC<SessionListProps> = ({sessions, deleteClick, startSession}) => {
    return (
        <GlassCard>
            <CardTitle>Recent Sessions</CardTitle>
            <SessionListWrapper>
                {recentLastNSessions(sessions, 2).map((session, index) => (
                    <SingleSessionCard key={index} session={session} deleteClick={deleteClick} startSession={startSession}/>
                ))}
            </SessionListWrapper>
        </GlassCard>
    )
}

interface SessionCardProps {
    session: Session;
    deleteClick: (session: Session) => void;
    startSession: (session: Session) => void;
}

export const SingleSessionCard: React.FC<SessionCardProps> = ({session, deleteClick, startSession}) => {
    return (
        <GlassCard>
            <SessionTitle onClick={() => startSession(session)}>{session?.title ? session?.title : "Untitled Session"}</SessionTitle>
            <SessionContent onClick={() => startSession(session)}>{session?.description ? session?.description : 'Tap To Start Talking'}</SessionContent>
            <SessionHashtags onClick={() => startSession(session)}>
                {session?.hashtags?.map((hashtag, index) => (
                    <Hashtag key={index}>{hashtag}</Hashtag>
                ))}
            </SessionHashtags>
            <SessionMeta>
                <span onClick={() => startSession(session)}>{timeAgo(session.updated)}</span>
                <DeleteButton onClick={() => deleteClick(session)}><FiTrash2/></DeleteButton>
            </SessionMeta>
        </GlassCard>
    )
}